import authorization from './authorization';
import claim from './claim';
import claimChat from './claimChat';
import repair from './repair';
import store from './store';
import reportChat from './reportChat';
import websocket from './websocket';
import i18n from './i18n';
import webPush from './web-push';
import page from './page';

const sagas: Array<any> = [
  websocket, // Keep webscoket above authorization saga
  authorization,
  claim,
  claimChat,
  repair,
  // user,
  store,
  reportChat,
  i18n,
  webPush,
  page
];

export default sagas;
