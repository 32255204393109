import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { ActionModal, ActionModalItem, Modal } from 'Components';
// import { ReactComponent as SaveIcon } from 'Assets/save.svg';
// import { ReactComponent as TrashIcon } from 'Assets/trash-black.svg';
// import { ReactComponent as ChatIcon } from 'Assets/chat-icon.svg';
// import styles from './SaveClaimAsDraftModal.module.scss';

const MODAL_TYPE = 'modal';
const COMPACT_TYPE = 'compact';
const DEFAULT_MODAL_TYPE = MODAL_TYPE;

interface ISaveClaimAsDraftModal {
  type?: 'modal' | 'compact';
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onDeny: () => void;
  withReopenChat?: boolean;
}

const STRATEGY = {
  [MODAL_TYPE]: SaveAsDraftModal,
  [COMPACT_TYPE]: SaveAsDraftCompact,
};

function SaveAsDraftModal(props: Omit<ISaveClaimAsDraftModal, 'type'>) {
  // const { t } = useTranslation();
  return (
    <></>
    // <Modal open={props.open} onClose={props.onClose} headerClassName={styles.saveModalHeader}>
    //   <div className={styles.saveModalContainer}>
    //     <ActionModalItem
    //       onClick={props.onConfirm}
    //       icon={<SaveIcon />}
    //       text={t('saveAsDraft.saveActionTitle')}
    //       description={t('saveAsDraft.saveActionDescription')}
    //       className={styles.actionItem}
    //     />
    //     {props.withReopenChat ? (
    //       <ActionModalItem
    //         onClick={props.onDeny}
    //         icon={<ChatIcon />}
    //         text={t('saveAsDraft.reopenChatTitle')}
    //         description={t('saveAsDraft.reopenChatDescription')}
    //         className={styles.actionItem}
    //       />
    //     ) : (
    //       <ActionModalItem
    //         onClick={props.onDeny}
    //         icon={<TrashIcon />}
    //         text={t('saveAsDraft.discardActionTitle')}
    //         description={t('saveAsDraft.discardActionDescription')}
    //         className={styles.actionItem}
    //       />
    //     )}
    //   </div>
    // </Modal>
  );
}

function SaveAsDraftCompact(props: Omit<ISaveClaimAsDraftModal, 'type'>) {
  // const { t } = useTranslation();
  return (    <></>
    // <ActionModal open={props.open} onClose={props.onClose} className={styles.actionsList}>
    //   <ActionModalItem
    //     onClick={props.onConfirm}
    //     icon={<SaveIcon />}
    //     text={t('saveAsDraft.saveActionTitle')}
    //     description={t('saveAsDraft.saveActionDescription')}
    //     className={styles.actionItem}
    //   />
    //   {props.withReopenChat ? (
    //     <ActionModalItem
    //       onClick={props.onDeny}
    //       icon={<ChatIcon />}
    //       text={t('saveAsDraft.reopenChatTitle')}
    //       description={t('saveAsDraft.reopenChatDescription')}
    //       className={styles.actionItem}
    //     />
    //   ) : (
    //     <ActionModalItem
    //       onClick={props.onDeny}
    //       icon={<TrashIcon />}
    //       text={t('saveAsDraft.discardActionTitle')}
    //       description={t('saveAsDraft.discardActionDescription')}
    //       className={styles.actionItem}
    //     />
    //   )}
    // </ActionModal>
  );
}

export default function SaveDataAsDraftModal({
  type = DEFAULT_MODAL_TYPE,
  ...props
}: ISaveClaimAsDraftModal) {
  const Component = STRATEGY[type] as React.FC<Omit<ISaveClaimAsDraftModal, 'type'>>;

  return <Component {...props} />;
}
