import { Button } from "Components";
import React from 'react';
import { Modal } from 'rsuite';

import useTranslate from "Hooks/useTranslate";
import style from './ModalNotAirlineSupported.module.scss';
import CustomModalHeader from "Components/CustomModalHeader/CustomModalHeader";


type MainPropsT = {
    actionClose: () => void,
    actionCloseModalMain: () => void,
    airlineCode: string,
    show?: boolean,
}

const ModalNotAirlineSupported: React.FC<MainPropsT> = ({ actionClose, actionCloseModalMain, airlineCode, show = false }) => {
    const t = useTranslate();

    return (
        <Modal open={show}>
            <CustomModalHeader 
                title={`${t('createClaim.headerNoAirlineSupport')}${airlineCode ? ' (' + airlineCode + ')' : ''}`} 
                onClose={actionClose} 
                fontColor="#f14915"
            />
            <div className={style.root}>
                {t('createClaim.labelNoAirlineSupport')}
            </div>
            <div className={style.buttons}>
                <Button onClick={actionCloseModalMain} type={'button'} color={'primary'} block>{t('createClaim.duplicateModalCloseButton')}</Button>
            </div>
        </Modal>
    );
}

export default ModalNotAirlineSupported;