import { SET_PAGE_CONFIG, setPageConfig } from 'Store/modules/page';
import { call, takeLatest, put, delay } from 'redux-saga/effects';
import api from 'api';

function* getConfig(retryCount = 3, retryDelay = 1500): any {
    for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
            const { data } = yield call(api().page.getConfig);

            yield put(setPageConfig({
                ...data,
                UPLOAD_MAX_ALLOWED_FILES_SIZE: data.UPLOAD_MAX_ALLOWED_FILES_SIZE * 1000000
            }));

            return;
        } catch (error) {
            if (attempt === retryCount) {
                console.error('Max retry attempts reached. Failed to fetch config.', error);
                return;
            }

            yield delay(retryDelay);
        }
    }
}

export default function* page(): Generator {
    yield call(getConfig);
    yield takeLatest(SET_PAGE_CONFIG, getConfig);
}
