
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Radio } from "rsuite";
import cx from 'classnames';

import { ReactComponent as TrashIcon } from 'Assets/trash-black.svg';
import { ReactComponent as PenIcon } from 'Assets/pen.svg';

import { deleteDraft, isDraftEmpty, readDraftImage, setNewDraft } from "services/draft";
import { IClaimDraft, IImageFile, IImageT } from "Store/Types/claim";
import { makeSlug, setLuggageTypeTitleLang } from "Helpers/strings";
import ImageDecrypt from "Components/ImageDecrypt/ImageDecrypt";
import LoadingCss from "Components/LoadingCss/LoadingCss";
import style from './ModalChooseDraft.module.scss';
import { runNotify } from "Helpers/notifications";
import { formatDateWithTime } from "Helpers/date";
import { setImageDataUrl } from "Helpers/files";
import { Button } from "Components";
import CustomModal from 'Components/CustomModal/CustomModal';

type MainPropsT = {
    drafts: IClaimDraft[],
    setState: (data: any) => void,
    t: (data: string, params?: any) => string,
    // actionDraftReadyCheck: (draft: IClaimDraft) => void
}

const ModalChooseDraft: React.FC<MainPropsT> = ({ drafts, setState, t }) => {

    const [processing, setProcessing] = useState(false);
    const [draftSelected, setDraftSelected] = useState<IClaimDraft|null>(null);

    useEffect(() => {
        if (drafts.length === 0){
            setDraftSelected(null);
        } else if (drafts.length === 1){
            setDraftSelected(drafts[0]);
        }
    }, [drafts]);

    const actionClose = useCallback(() => {
        setState({ type: 'SET_DRAFTS', data: [] });
    }, [setState]);

    const actionCancel = useCallback(async () => {
        setProcessing(true);
        for(const draft of drafts){
            await deleteDraft(draft.id);
        }
        setNewDraft((result, data) => {
            if (result && data){
                setState({ type: 'SET_DRAFT', data, drafts: [] });
            } else {
                runNotify({ message: 'Error while seting new draft', type: 'error' });
            }
        });
    }, [drafts, setState]);

    const actionSetDraft = useCallback((event: any, draft: IClaimDraft) => {
        event.preventDefault();
        if (draftSelected?.id === draft.id){
            return setDraftSelected(null);
        }
        setDraftSelected(draft);
    }, [draftSelected]);

    const actionImportDataFromDraft = useCallback(async (draft: IClaimDraft) => {
        setState({ type: 'IMPORT_CLAIM_DATA_FROM_DRAFT', data: draft });

        let resultPhotos: IImageT[] = [];
        let resultDamage: IImageT[] = [];
        const getImageFromUrl = async (url: string, type: string, originalDraftKey: string) => {
            const dataResult = await setImageDataUrl(url);
            if (dataResult){
                if (type === 'damage'){
                    resultDamage.push({...dataResult, type, originalDraftKey });
                } else {
                    resultPhotos[type] = {...dataResult, type, originalDraftKey };
                }
            }
        }

        const attachments = [...draft?.case?.files?.photos || [], ...draft?.case?.files?.documents || []];
        await Promise.all(attachments.map(async (photo) => {
            const resultUrl = await readDraftImage(photo.original.key);
            if (resultUrl){
                await getImageFromUrl(resultUrl, photo.type, photo.original.key);
            } else if (photo.original.url){
                await getImageFromUrl(photo.original.url, photo.type, photo.original.key);
            }
        }));

        setState({
            type: 'ADD_WT_ATTACHEMENTS',
            photoDamages: resultDamage,
            photos: resultPhotos
        });

    }, [setState]);

    const actionConfirm = useCallback(async () => {
        setProcessing(true);

        actionImportDataFromDraft(draftSelected);

        setState({ type: 'SET_DRAFT', data: draftSelected, drafts: [] });
        setProcessing(false);

        if ((draftSelected.airlineId && draftSelected.landingStation && draftSelected.airlineDateLanding) || !!draftSelected.referenceNumber){
            setState({ type: 'RUN_CHECK' });
        }

    }, [draftSelected, actionImportDataFromDraft, setState]);

    const ImageDraft = useCallback((draft: IClaimDraft) => {
        if (!Array.isArray(draft?.case?.files?.photos)){
            return;
        }
        const damage: IImageFile = draft.case.files.photos.find(f => f.type === 'damage');
        if (!!damage){
            return <div className={style.imageDraft}><ImageDecrypt imageKey={damage.thumbnail.key} /></div>
        }
        const anyImage: IImageFile = draft.case.files.photos[0];
        if (!!anyImage){
            return <div className={style.imageDraft}><ImageDecrypt imageKey={anyImage.thumbnail.key} /></div>
        }
    }, []);

    const detailsDraft = useMemo(() => (draft: IClaimDraft): JSX.Element[] => {
        const result: JSX.Element[] = [];
        
        if (draft.referenceNumber) {
            result.push(<div className={style.refNumber}><span className={style.label}>{t('createClaim.ref')}:</span> <strong>{draft.referenceNumber}</strong></div>);
        }
        
        if (draft?.case?.luggageType) {
            result.push(<span className={style.extendLine}><span className={style.label}><strong>{t(setLuggageTypeTitleLang(makeSlug(draft.case.luggageType)))}</strong></span></span>);
        }
        
        if (draft.airlineName) {
            result.push(<span className={style.extendLine}>{draft.airlineName}</span>);
        }
        
        if (draft.landingStation) {
            result.push(<span className={style.extendLine}>{draft.landingStation.country} - {draft.landingStation.name}</span>);
        }
        
        if (draft?.luggage?.brand) {
            result.push(<span className={style.extendLine}><span className={style.label}>{t('createClaim.detBrand')}:</span> <strong>{draft.luggage.brand}</strong></span>);
        }
        
        if (draft?.luggage?.color) {
            result.push(<span className={style.extendLine}><span className={style.label}>{t('createClaim.sumColor')}:</span> <strong>{draft.luggage.color}</strong></span>);
        }
    
        return result;
    }, [t]);

    const removeDraft = useCallback(async (draft: IClaimDraft) => {
        setProcessing(true);
        const result = await deleteDraft(draft.id);
        if (result){
            if (draftSelected && draftSelected.id === draft.id){
                setDraftSelected(null);
            }
            setState({ type: 'REMOVE_DRAFT', draftId: draft.id });
        }
        setProcessing(false);
    }, [setState, draftSelected]);

    return (
        <CustomModal open={drafts.length > 0 && !isDraftEmpty(drafts)} onClose={actionClose}>
            <div className={style.root}>
                <Modal.Body className={style.body}>
                    <div className={style.headerContent}>
                        <div className={style.headerText}>{t('createClaim.draftHeader')}</div>
                        <div className={style.panIco}>
                            <PenIcon width={100} height={100} />
                        </div>
                        {processing &&
                            <div className={style.processing}><LoadingCss width={70} /></div>
                        }
                        {drafts.length > 1?
                            <>{t('createClaim.draftMany')}</>:
                            <>{t('createClaim.draftOne')}</>
                        }
                        <div className={style.headerExplanation}>
                            {t('createClaim.draftContentInfo', { days: 30 })}
                        </div>                    </div>
                    <div className={style.drafts}>
                        {drafts.map(draft => {

                            const dataExtend = detailsDraft(draft);

                            return (
                                <div key={`draft-${draft.id}`} className={cx(style.draft, { [style.selected]: draftSelected?.id === draft.id })}>
                                    <div className={style.draftBody} onClick={(event) => actionSetDraft(event, draft)}>
                                        {drafts.length > 1 &&
                                            <div className={style.switcher}>
                                                <Radio value={draft.id} checked={draftSelected?.id === draft.id} onChange={() => null} />
                                            </div>
                                        }
                                        {ImageDraft(draft)}
                                        <div className={style.content}>
                                            {t('createClaim.draftLastModify')}: <strong>{formatDateWithTime(new Date(draft.updatedAt || draft.createdAt))}</strong>
                                            {dataExtend.length > 0 &&
                                                <div className={style.lineData}>
                                                    {dataExtend.map((data, idx) => {
                                                        return (
                                                            <React.Fragment key={`${draft.id}-${idx}`}>
                                                                {(idx > 0 && !(draft.referenceNumber && idx === 1)) &&
                                                                    <>, </>
                                                                }
                                                                {data}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={style.trash} onClick={() => removeDraft(draft)}><TrashIcon /></div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.buttons}>
                    <Button onClick={actionCancel} type={'button'} color={'secondary'}>
                        {t('createClaim.draftCancelButton')}
                    </Button>
                    <Button onClick={actionConfirm} disabled={!draftSelected || processing}>
                        {t('createClaim.draftActionButton')}
                    </Button>
                </Modal.Footer>
            </div>
        </CustomModal>
    );
}
export default ModalChooseDraft;