import { loading, loaded, error } from 'Helpers/store-async';
import { isIOS } from 'Helpers/responsiveContainers';
import { RegisterT, UserT } from 'api/modules/user';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const SEND_CODE_FAILURE = 'SEND_CODE_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOG_OUT = 'LOG_OUT';

type LoginSuccessT = {
  type: typeof LOGIN_SUCCESS;
};

type LoginFailureT = {
  type: typeof LOGIN_FAILURE;
  error: string;
};

export type LogoutRequestT = {
  type: typeof LOGOUT_REQUEST;
};

type LogoutSuccessT = {
  type: typeof LOGOUT_SUCCESS;
};

type LogoutFailureT = {
  type: typeof LOGOUT_FAILURE;
  error: string;
};

type ResetLoginStateT = {
  type: typeof LOGIN_RESET;
};

export type RegisterUserRequestT = {
  type: typeof REGISTER_USER_REQUEST;
  data: RegisterT;
};

type RegisterUserSuccessT = {
  type: typeof REGISTER_USER_SUCCESS;
};

type RegisterUserFailureT = {
  type: typeof REGISTER_USER_FAILURE;
  error: string;
};

type SendCodeSuccessT = {
  type: typeof SEND_CODE_SUCCESS;
};

type SendCodeFailureT = {
  type: typeof SEND_CODE_FAILURE;
  error: string;
};

export type ActionTypeT =
  | LoginSuccessT
  | LoginFailureT
  | ResetLoginStateT
  | LogoutRequestT
  | LogoutSuccessT
  | LogoutFailureT
  | RegisterUserRequestT
  | RegisterUserSuccessT
  | RegisterUserFailureT
  | SendCodeSuccessT
  | SendCodeFailureT;

export type StateT = {
  login: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
  info: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
  code: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
  resend: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
  };
};

const initialState: StateT = {
  login: {
    loading: false,
    loaded: false,
    error: null,
  },
  info: {
    loading: false,
    loaded: false,
    error: null,
  },
  code: {
    loading: false,
    loaded: false,
    error: null,
  },
  resend: {
    loading: false,
    loaded: false,
    error: null,
  },
};

export default function authorizationReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        login: loaded(state.login),
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        login: error(state.login, action.error),
      };
    }
    case LOGIN_RESET: {
      return {
        ...state,
        login: { ...initialState.login },
      };
    }
    case REGISTER_USER_REQUEST: {
      return {
        ...state,
        info: loading(state.info)
      };
    }
    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        info: loaded(state.info),
      };
    }
    case REGISTER_USER_FAILURE: {
      return {
        ...state,
        info: error(state.info, action.error),
      };
    }
    case SEND_CODE_SUCCESS: {
      return {
        ...state,
        code: loaded(state.code),
      };
    }
    case SEND_CODE_FAILURE: {
      return {
        ...state,
        code: error(state.code, action.error),
      };
    }
    default: {
      return state;
    }
  }
}

export function loginSuccess(data: UserT): LoginSuccessT {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginFailure(error: string): LoginFailureT {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

export function resetLoginState(): ResetLoginStateT {
  return {
    type: LOGIN_RESET,
  };
}

export function registerUserRequest(data: RegisterT): RegisterUserRequestT {
  return {
    type: REGISTER_USER_REQUEST,
    data,
  };
}

export function registerUserSuccess(): RegisterUserSuccessT {
  return {
    type: REGISTER_USER_SUCCESS,
  };
}

export function registerUserFailure(error: string): RegisterUserFailureT {
  return {
    type: REGISTER_USER_FAILURE,
    error,
  };
}

export function sendCodeSuccess(): SendCodeSuccessT {
  return {
    type: SEND_CODE_SUCCESS,
  };
}

export function sendCodeFailure(error: string): SendCodeFailureT {
  return {
    type: SEND_CODE_FAILURE,
    error,
  };
}

export function logout() {
  if (isIOS()) {
    window.location.reload()
  }
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  return {
    type: LOG_OUT,
  };
}
