import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { PhotoKeys, RequiredDocumentT, StateLocalT, stepsMissingDocsScreens } from '../../CreateForm';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import ExternalnfoData from "Components/ExternalnfoData/ExternalnfoData";
import { ReportUploadBox } from 'PageComponents/Chat';
import { iPhone, isFirefox } from 'Helpers/browser';
import { runNotify } from 'Helpers/notifications';
import style from './MissedPhoto.module.scss';
import usePhotoKey from "./usePhotoKey";

const MaxPhotos = 3;

type UpdateT = {
    type: string,
    field: string,
    value: any
}

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void,
    missingType: string,
    minPhotos?: number,
    updateSteps: (data: UpdateT[]) => void,
    updateDataFile: (file: IImage|null, mKey: string, oldImage: IImage|null) => void,
}

const MissedPhoto: React.FC<MainProps> = ({ state, updateClaim, setValidStep, missingType, minPhotos = 1, updateSteps, updateDataFile }) => {

    const [grouped, setGrouped] = useState<RequiredDocumentT[]>([state.requiredMissingDocuments.find(doc => doc.Missing_Document_Type__c === missingType)]);
    const photoKey = usePhotoKey(missingType);

    useEffect(() => {
        const DOC = state.requiredMissingDocuments.find(doc => doc.Missing_Document_Type__c === missingType);
        if (DOC && DOC?.Set__c && DOC.Set__c !== '0'){
            const changes: UpdateT[] = [];
            const grouped = state.requiredMissingDocuments.filter(doc => doc.Set__c === DOC.Set__c && stepsMissingDocsScreens.includes(doc.Missing_Document_Type__c)).sort((a,b) => a.Missing_Document_Type__c === DOC.Missing_Document_Type__c ? (isFirefox() ? 0 : -1) -1 : (isFirefox() ? 1 : 0));
            grouped.forEach(data => {
                if (data.Missing_Document_Type__c !== DOC.Missing_Document_Type__c){
                    changes.push({ field: 'optional', type: data.Missing_Document_Type__c, value: true });
                }
            });
            if (changes.length > 0){
                updateSteps(changes);
            }
            return setGrouped(grouped);
        }
        return setGrouped([DOC]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.requiredMissingDocuments, missingType]);

    useEffect(() => {

        let valid = false;
        grouped.forEach(gr => {
            const _key = PhotoKeys?.[gr?.Missing_Document_Type__c] || photoKey;
            if (Array.isArray(state.claimData.photos[_key]) ? (state.claimData.photos[_key] as IImage[]).length >= minPhotos : !!state.claimData.photos[_key]){
                valid = true;
            }
        });
        setValidStep(valid);
        // setValidStep(Array.isArray(state.claimData.photos[photoKey]) ? (state.claimData.photos[photoKey] as IImage[]).length >= minPhotos : !!state.claimData.photos[photoKey]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.claimData.photos, grouped]);
    const { t } = useTranslation();

    const actionChangeImage = useCallback((image: IImage | null, mType: string, pKey: string, oldImage: IImage|null) => {
        updateDataFile(image, pKey, oldImage);
        updateClaim('photos', {
            ...state.claimData.photos,
            [pKey]: image
        });
        const requireDocument = state.requiredMissingDocuments.find(doc => doc.Missing_Document_Type__c === mType);
        if (requireDocument){
            if (!image){
                return updateClaim('missingDocuments', state.claimData.missingDocuments.filter(doc => doc.requireDocument.Missing_Document_Type__c !== mType));
            }
            if (!state.claimData.photos[pKey]){
                updateClaim('missingDocuments', [
                    ...state.claimData.missingDocuments,
                    {
                        document: image,
                        requireDocument
                    }
                ]);
            } else {
                updateClaim('missingDocuments', state.claimData.missingDocuments.map(doc => {
                    const file = (state.claimData.photos[pKey] as IImage).file;
                    if (doc.document.file === file && doc.requireDocument.Missing_Document_Type__c === mType){
                        doc.document = image;
                    }
                    return doc;
                }));
            }
        }
    }, [updateClaim, state.claimData.photos, state.requiredMissingDocuments, state.claimData.missingDocuments, updateDataFile]);

    const actionMultiChangeImage = useCallback((idx: number, image: IImage | null) => {
        console.log('change multi');
        if (Array.isArray(state.claimData.photos[photoKey])){
            const photos = state.claimData.photos[photoKey] as IImage[];
            const cameraIphone = iPhone() && (image.name === 'image.jpg' || image.name === 'image.jpeg' || image.name === 'image.heic');
            if (cameraIphone || !photos.some(photo => photo.file.name === image.file.name)){
                updateClaim('photos', {
                    ...state.claimData.photos,
                    [photoKey]: photos.map((photo, index) => {
                        if (idx === index) photo = image;
                        return photo;
                    })
                });

                updateClaim('missingDocuments', state.claimData.missingDocuments.map(doc => {
                    if (doc.document.url !== image.url){
                        doc.document = image;
                    }
                    return doc;
                }));

                updateDataFile(image, photoKey, null);

            } else {
                runNotify({ message: t('createClaim.errorUploadAlreadyPhoto'), type: 'error' });
            }
        }
    }, [state.claimData.photos, photoKey, updateClaim, t, state.claimData.missingDocuments, updateDataFile]);

    const actionMultiAddImage = useCallback((image: IImage, pKey: string) => {
        if (Array.isArray(state.claimData.photos[pKey])){
            const photos = state.claimData.photos[pKey] as IImage[];
            const cameraIphone = iPhone() && (image.name === 'image.jpg' || image.name === 'image.jpeg' || image.name === 'image.heic');
            if (cameraIphone || !photos.some(photo => photo.file.name === image.file.name)){

                updateClaim('photos', {
                    ...state.claimData.photos,
                    [pKey]: [
                        ...photos,
                        image
                    ]
                });

                const requireDocument = state.requiredMissingDocuments.find(doc => doc.Missing_Document_Type__c === missingType);
                if (requireDocument){
                    updateClaim('missingDocuments', [
                        ...state.claimData.missingDocuments,
                        {
                            document: image,
                            requireDocument
                        }
                    ]);
                }

                updateDataFile(image, photoKey, null);

            } else {
                runNotify({ message: t('createClaim.errorUploadAlreadyPhoto'), type: 'error' });
            }
        }
    }, [state.claimData.photos, updateClaim, t, state.requiredMissingDocuments, missingType, state.claimData.missingDocuments, updateDataFile, photoKey]);

    const removeDamageImage = useCallback((image: IImage) => {

        const photos = state.claimData.photos[photoKey];
        const isArray = Array.isArray(state.claimData.photos[photoKey]);
        updateClaim('photos', {
            ...state.claimData.photos,
            [photoKey]: isArray ? [
                ...(photos as IImage[]).filter(photo => photo.file.lastModified !== image.file.lastModified)
            ] : undefined
        });
        const requireDocument = state.requiredMissingDocuments.find(doc => doc.Missing_Document_Type__c === missingType);
        if (requireDocument){
            updateClaim('missingDocuments', state.claimData.missingDocuments.filter(doc => doc.document.url !== image.url));
        }
        updateDataFile(null, photoKey, image);

    }, [state.claimData.photos, photoKey, updateClaim, state.requiredMissingDocuments, missingType, state.claimData.missingDocuments, updateDataFile]);

    const getHeader = (_key: string) => {
        const doc = state.steps.find(step => step.type === _key);
        return doc ? doc.headerText : _key;
    }

    return (
        <div className={style.root}>

            <div className={style.header}>
                {state.step.headerTitle}
            </div>

            {grouped.map((gr, idxGroup) => {
                const _key = PhotoKeys?.[gr?.Missing_Document_Type__c] || photoKey;
                const dataExample = state?.missingDocumentsAdditionalInfo?.find(info => info.id === gr?.Id) || null;

                return (
                    <div className={cx(style.imageGroup, { [style.imageGroupMore]: idxGroup > 0 })} key={`gr_${gr?.Id || '_x'}`}>

                        {idxGroup > 0 &&
                            <div className={style.orText}>
                                <div className={style.orHeader}><span className={style.or}>... {t('common.or')}</span> <span className={style.orMain}>{getHeader(gr?.Missing_Document_Type__c)}</span></div>
                            </div>
                        }

                        {(Array.isArray(state.claimData.photos[_key])) ?
                            <div className={style.images}>
                                {(state.claimData.photos[_key] as IImage[]).map((photo, idx) => {
                                    return (
                                        <div className={style.imagePhoto} key={`photoDamage_${photo.file.name}`}>
                                            <ReportUploadBox
                                                value={photo}
                                                removable={true}
                                                onRemove={() => removeDamageImage(photo)}
                                                onChange={(value) => actionMultiChangeImage(idx, value)}
                                                name={'damagePhoto-'+idx}
                                                hideName
                                                excludeImages={state.imagesInClaim}
                                                fitWidth
                                                onlyImages
                                                id={'file_new_claim_missed_photo_' + idx}
                                            />
                                        </div>
                                    )
                                })}
                                {(state.claimData.photos[_key] as IImage[]).length < MaxPhotos &&
                                    <div className={cx(style.imagePhoto, style.imagePhotoAdd)}>
                                        <ReportUploadBox
                                            onChange={(value) => actionMultiAddImage(value, PhotoKeys[gr.Missing_Document_Type__c || missingType])}
                                            name={'missingDocument-addNew'}
                                            excludeImages={state.imagesInClaim}
                                            hideName
                                            addMode
                                            fitWidth
                                            id={'file_new_claim_missed_photo'}
                                            // onlyImages
                                        />
                                    </div>
                                }
                            </div>:
                            <div className={style.imagePhoto}>
                                <ReportUploadBox
                                    value={state.claimData.photos[_key] as IImage}
                                    removable={true}
                                    onChange={im => actionChangeImage(im, gr.Missing_Document_Type__c || missingType, PhotoKeys[gr.Missing_Document_Type__c || missingType], state.claimData.photos[_key] as IImage)}
                                    name={'front'}
                                    hideName
                                    width={212}
                                    excludeImages={state.imagesInClaim}
                                    id={'file_new_claim_missed_photo'}
                                />
                            </div>
                        }
                        {dataExample &&
                            <ExternalnfoData t={t} missingDocumentsAdditionalInfo={dataExample} missingType={missingType} />
                        }
                    </div>
                )
            })}

        </div>
    );
}
export default React.memo(MissedPhoto);