import { setDefaultCurrency } from "Helpers/claim";
import { StateLocalT, StepT } from "./CreateForm";
import { IClaimDraft } from "Store/Types/claim";
import { StepsE } from "Store/Enums/claim";

const Store = (state: StateLocalT, action: any): StateLocalT => {

    switch(action.type){

        case 'SET_STATE':

            return action.data

        // wyzerowanie stanu dla dodania nowego zgłoszenia
        case 'SET_NEW_STATE':

            return {
                ...action.defaultState,
                steps: action.steps,
                stepIndex: 0,
                step: action.steps[0],
                brands: state.brands,
                damageOptions: state.damageOptions,
                finish: false,
                baggageTypes: state.baggageTypes,
                materials: state.materials,
                airlines: state.airlines,
                colors: state.colors,
                referenceNumberCounter: 0,
                lastAddClaimId: '',
                currencies: state.currencies,
                hideHeader: false,
                landingStationSelected: null,
                landingStations: state.landingStations,
                luggageTypes: state.luggageTypes,
                claimData: {
                    ...action.defaultState.claimData,
                    clientLocationAirlineCountry: state.claimData.clientLocationAirlineCountry,
                    contact: {
                        ...state.claimData.contact
                    },
                    details: {
                        ...action.defaultState.claimData.details,
                        currency: state.claimData.details.currency
                    }
                }
            }

        case 'SET_FINISH':

            return {
                ...state,
                finish: action.data
            }

        case 'SET_STEP':

            const stepResult = state.steps[action.data];

            return {
                ...state,
                stepIndex: action.data,
                step: stepResult,
                editStepIndex: null,
                viewSlideSummaryType: state.steps.find((step, idx) => idx === state.editStepIndex)?.type || null
            }

        case 'CLOSE_MODAL_TAG_NUMBERS':
                return {
                    ...state,
                    showModalTagNumbers: false,
                    claimData: {
                        ...state.claimData,
                        referenceNumber:''
                    },
                };

        case 'SET_STEP_EDIT':

            return {
                ...state,
                stepIndex: action.data,
                step: state.steps[action.data],
                editStepIndex: action.editStepIndex
            }

        case 'SET_ONLY_STEP_EDIT':

            return {
                ...state,
                editStepIndex: action.data
            }

        case 'SET_STEPS':

            return {
                ...state,
                steps: action.data,
                step: action.data && action.data.length > 0 ? action.data[0] : null
            }

        case 'UPDATE_STEPS':

            return {
                ...state,
                steps: action.data
            }

        case 'SET_WIDTH_SLIDER':

            return {
                ...state,
                widthSlider: action.width
            }

        case 'SET_BASIC_DATA':

            return {
                ...state,
                brands: action.brands,
                airlines: action.airlines,
                colors: action.colors,
                baggageTypes: action.baggageTypes,
                materials: action.materials,
                currencies: action.currencies,
                landingStations: action.landingStations,
                luggageTypes: action.luggageTypes,
                claimData: {
                    ...state.claimData,
                    details: {
                        ...state.claimData.details,
                        currency: setDefaultCurrency(action.currencies, state.claimData.contact.country.code)
                        // currency: action.currencies && Array.isArray(action.currencies) && action.currencies.length > 0 ? action.currencies[0].code : '€'
                    }
                },
                damageOptions: action.damageOptions,
                referenceNumberExamples: action?.referenceNumberExamples || []
            }

        case 'CLEAR_DATA_NEXT_TAG': 

            const indexStepTypeLuggage = state.steps.findIndex(step => step.type === StepsE.TYPE_OF_LUGGAGE );
            const stepResultNext = state.steps[indexStepTypeLuggage];

            return {
                ...state,
                finish: false,
                hideHeader: false,
                claimData: {
                    ...state.claimData,
                    luggageType: '',
                    luggageColor: '',
                    luggageMaterial: '',
                    damage: [],
                    details: {
                        brand: '',
                        model: '',
                        serialNumber: '',
                        date: null,
                        cost: 0,
                        costKnow: true,
                        length: 0,
                        height: 0,
                        capacity: 0,
                        width: 0,
                        functions: '',
                        currency: state.claimData.details.currency,
                        size: state.claimData.details.size,
                    },
                    photos: {
                        ...state.claimData.photos,
                        front: undefined,
                        back: undefined,
                        top: undefined,
                        bottom: undefined,
                        left: undefined,
                        right: undefined,
                    },
                    photoDamages: []
                },
                stepIndex: indexStepTypeLuggage,
                step: stepResultNext,
                editStepIndex: null
        }

        case 'SET_STEP_VALID':

            return {
                ...state,
                steps: state.steps.map((step: StepT) => {
                    if (step.type === state.step?.type){
                        state.valid = action.data
                    }
                    return step;
                }),
                step: !state.step ? null : {
                    ...state.step,
                    valid: action.data
                }
            }

        case 'UPDATE_CLAIM_DATA':

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    [action.dataType]: action.data
                }
            }

        case 'UPDATE_STATE':

            return {
                ...state,
                [action.dataType]: action.data
            }

        case 'GO_NEXT':

            const baseSteps = [...state.steps];
            const indexActual = baseSteps.findIndex(step => step.type === state.step.type);
            let indexGoNext: number = indexActual;
            for(let i=0;i<=baseSteps.length;i++){
                if (!baseSteps[i].optional && i > indexActual){
                    indexGoNext = i;
                    break;
                }
            }
            
            return {
                ...state,
                goNext: 0,
                stepIndex: indexGoNext,
                step: baseSteps[indexGoNext],
                checkAddressByGoogle: 'noAction',
                casesInSalesforce: action?.casesInSalesforce ? action.casesInSalesforce : state.casesInSalesforce
            }

        case 'GO_PREV':

            const baseStepsPrev = [...state.steps];
            const indexActualPrev = baseStepsPrev.findIndex(step => step.type === state.step.type);
            let indexGoPrev: number = indexActualPrev;
            for(let i=baseStepsPrev.length;i>=0;i--){
                if (!baseStepsPrev[i]?.optional && i < indexActualPrev){
                    indexGoPrev = i;
                    break;
                }
            }

            return {
                ...state,
                goPrev: 0,
                stepIndex: indexGoPrev,
                step: baseStepsPrev[indexGoPrev]
            }

        case 'SET_LOADING':

            return {
                ...state,
                loading: action.data,
            }

        case 'SET_AIRLINE_LOADING':

            return {
                ...state,
                loadingAirline: action.data
            }

        case 'CLEAR_CHECK_DATA':

            return {
                ...state,
                airlineSupported: false,
                landingStationSupported: false,
                requiredMissingDocuments: [],
                loading: false,
                referenceNumberCounter: state.referenceNumberCounter + 1,
                claimData: {
                    ...state.claimData,
                    missingDocuments: []
                }
            }

        case 'SET_GEOLOCALIZATION':

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    contact: {
                        ...state.claimData.contact,
                        coords: {
                            lat: action.lat,
                            lon: action.lon,
                        }
                    }
                }
            }

        case 'SET_COUNTRY_NAME':

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    contact: {
                        ...state.claimData.contact,
                        country: {
                            ...state.claimData.contact.country,
                            name: action.data
                        }
                    }
                }
            }

        case 'SET_CHECK_DATA':

            // const steps = [...state.steps.map((step: StepT) => {
            //     return step;
            // })];

            const airline: { id: string, name: string } = {
                id: state.claimData.airlineId,
                name: state.claimData.airlineName,
            }

            let foundAirline = false;
            if (action.airlineCode){
                const findAirline = state.airlines.find(a => a.Name === action.airlineCode);
                if (findAirline){
                    airline.id = findAirline.Id;
                    airline.name = findAirline.Airline__c;
                    foundAirline = true;
                }
            }

            const redirectionToAirline = !action.airlineSupportedNoRefNum ? {
                url: action.redirectionToAirline?.url || '',
                ...(action.redirectionToAirline?.email && { email: action.redirectionToAirline.email })
            } : undefined;

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    referenceNumberStatus: action.referenceNumberStatus || state.claimData.referenceNumberStatus,
                    details: {
                        ...state.claimData.details,
                        currency: action.branchCurrenciesByLocation && Array.isArray(action.branchCurrenciesByLocation) && action.branchCurrenciesByLocation.length > 0 ? action.branchCurrenciesByLocation[0] : 'EUR'
                    },
                    airlineId: airline.id,
                    airlineName: airline.name,
                    referenceNumberSource: action.referenceNumberSource,
                    referenceNumberValid: action.checkValidReferenceNumber ? action.status || foundAirline : state.claimData.referenceNumberValid,
                    landingStationSelected: state.landingStations.find(l => l.name === action.landingStationCode) || state.claimData.landingStationSelected
                },
                airlineCode: action.airlineCode,
                landingStationCode: action.landingStationCode,
                airlineSupported: action.airlineSupported,
                airlineSupportedNoRefNum: action.airlineSupportedNoRefNum,
                redirectionToAirline,
                landingStationSupported: action.landingStationSupported,
                requiredMissingDocuments: action.requiredMissingDocuments,
                loading: false,
                loadingAirline: false,
                // steps,
                showInfoNotAirlineSupport: !action.airlineSupported,
                showInfoNotAirlineSupportAndContact: !action.airlineSupported,
                showInfoNotLandingStationSupport: !action.landingStationSupported,
                branchContact: action.branchContact || state.branchContact,
                tagNumbers: action.foundedBags || [],
                branchCurrenciesByLocation: action.branchCurrenciesByLocation || ['EUR'],
                showModalTagNumbers: !!action.foundedBags && Array.isArray(action.foundedBags) && action.foundedBags.length > 1,
                tagNumbersAccepted: [],
                wtBag: {
                    ...state.wtBag,
                    purchaseDate: !!action.foundedBags && Array.isArray(action.foundedBags) && action.foundedBags.length >=1 ? action.foundedBags[0]?.readedResults?.purchaseDate || null : null,
                    value: !!action.foundedBags && Array.isArray(action.foundedBags) && action.foundedBags.length >=1 ? action.foundedBags[0]?.readedResults?.value || null : null,
                    currencyIsoCode: !!action.foundedBags && Array.isArray(action.foundedBags) && action.foundedBags.length >=1 ? action.foundedBags[0]?.readedResults?.currencyIsoCode || null : null,
                },
                fk_caseInfo_bag_id: !!action.foundedBags && Array.isArray(action.foundedBags) && action.foundedBags.length === 1 ? (action.foundedBags[0]?.id || state.fk_caseInfo_bag_id) : state.fk_caseInfo_bag_id,
                casesInSalesforce: action?.duplicatesReferenceNumber && Array.isArray(action.duplicatesReferenceNumber) ? action.duplicatesReferenceNumber : [],
                requiredMissingDocumentsCheck: action.documentsOrigin,
                missingDocumentsAdditionalInfo: action.missingDocumentsAdditionalInfo,
                runCheck: false
            }

        case 'CLEAR_DUPLICATES_RN': return {
            ...state,
            casesInSalesforce: []
        }

        case 'SET_SHOW_MODAL_TAG_NUMBERS': return {
            ...state,
            showModalTagNumbers: action.data
        }

        case 'SET_TAG_NUMBERS_ACCEPTED':

            const actualBag = action.data.find(data => data.status === 'processing');

            return {
                ...state,
                tagNumbersAccepted: action.data,
                showModalTagNumbers: false,
                wtBag: {
                    ...state.wtBag,
                    purchaseDate: actualBag ? actualBag.purchaseDate : state.wtBag.purchaseDate,
                    value: actualBag ? actualBag.value : state.wtBag.value,
                    currencyIsoCode: actualBag ? actualBag.currencyIsoCode : state.wtBag.currencyIsoCode,
                },
                fk_caseInfo_bag_id: action.data.find(data => data.status === 'processing')?.id || null
            }

        case 'ADD_WT_ATTACHEMENTS':

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    photoDamages: [
                        ...state.claimData.photoDamages,
                        ...(Array.isArray(action.photoDamages) && action.photoDamages.length > 0 ? action.photoDamages : []),
                    ],
                    photos: {
                        ...state.claimData.photos,
                        ...action.photos
                    }
                }
            }

        case 'STEP_SHOW': 

            return {
                ...state,
                steps: [
                    ...state.steps.map(step => {
                        if (step.type === action.dataType){
                            step.optional = false;
                            step.missedDoc = false;
                        }
                        return step;
                    })
                ]
            }

        case 'SET_OPEN_DRAFT':

            return {
                ...state,
                openDraft: action.data
            }

        case 'SET_SHOW_INFO_AIRLINE_SUPPORT_AND_CONTRACT':

            return {
                ...state,
                showInfoNotAirlineSupportAndContact: action.data
            }

        case 'SET_SHOW_INFO_AIRLINE_SUPPORT':

            return {
                ...state,
                showInfoNotAirlineSupport: action.data
            }

        case 'SET_SHOW_INFO_LANDING_STATION_SUPPORT':

            return {
                ...state,
                showInfoNotLandingStationSupport: action.data
            }

        case 'READ_FROM_DRAFT':

            return {
                ...state,
                claimData: {
                    ...action.claimData,
                    details: {
                        ...action.claimData.details,
                        date: new Date(action.claimData.details.date)
                    },
                    airlineDateLanding: action.claimData.airlineDateLanding ? new Date(action.claimData.airlineDateLanding) : null
                },
                termsConditions: action.termsConditions,
                termsConcentData: action.termsConcentData,
            }

        case 'INCREASE_CHECKREFERENCE_NUMBER':

            return {
                ...state,
                referenceNumberCounter: state.referenceNumberCounter + 1
            }

        case 'SET_SENDING':

            return {
                ...state,
                sending: action.data
            }

        case 'SET_HIDE_HEADER':

            return {
                ...state,
                hideHeader: action.data
            }

        case 'SET_LANDING_STATION':

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    landingStationSelected: action.data
                }
            }

        case 'SET_CHECK_CONTACT_ADRESS':

            return {
                ...state,
                checkAddressByGoogle: action.data
            }

        case 'SET_IMAGES_IN_CLAIM':

            return {
                ...state,
                imagesInClaim: action.data
            }
        
        case 'SET_DRAFTS': return {
            ...state,
            drafts: action.data,
            draft: action.draft || state.draft
        }

        case 'SET_DRAFT': return {
            ...state,
            draft: action.data,
            drafts: Array.isArray(action.drafts) ? action.drafts : state.drafts
        }

        case 'SET_READ_OCR': return {
            ...state,
            readOcr: action.data,
            ocrData: !action.data ? null : state.ocrData
        }

        case 'SET_OCR_DATA': return {
            ...state,
            ocrData: action.data,
            claimData: {
                ...state.claimData,
                referenceNumber: action.referenceNumber || state.claimData.referenceNumber
            }
        }

        case 'IMPORT_CLAIM_DATA_FROM_DRAFT':

            const actionData: IClaimDraft = { ...action.data }

            return {
                ...state,
                claimData: {
                    ...state.claimData,
                    referenceNumber: actionData.referenceNumber || '',
                    airlineName: actionData.airlineName || state.claimData.airlineName,
                    airlineId: actionData.airlineId || state.claimData.airlineId,
                    airlineDateLanding: actionData.airlineDateLanding ? new Date(action.data.airlineDateLanding) : state.claimData.airlineDateLanding,
                    luggageType: actionData?.case?.luggageType || state.claimData.luggageType,
                    landingStationSelected: actionData?.landingStation || state.claimData.landingStationSelected,
                    luggageColor: actionData?.luggage?.color || state.claimData.luggageColor,
                    luggageMaterial: actionData?.luggage?.material || state.claimData.luggageMaterial,
                    details: {
                        ...state.claimData.details,
                        brand: actionData?.luggage?.brand || state.claimData.details.brand,
                        model: actionData?.luggage?.model || state.claimData.details.model,
                        size: actionData?.luggage?.size || state.claimData.details.size,
                        date: actionData?.luggage?.date ? new Date(action.data?.luggage?.date) : state.claimData.details.date,
                        cost: actionData?.luggage?.cost || state.claimData.details.cost,
                        currency: actionData?.luggage?.currencyIsoCode || state.claimData.details.currency,
                        length: actionData?.luggage?.length || state.claimData.details.length,
                        functions: actionData?.luggage?.functions || state.claimData.details.functions,
                        height: actionData?.luggage?.height || state.claimData.details.height,
                        width: actionData?.luggage?.width || state.claimData.details.width,
                        serialNumber: actionData?.luggage?.serialNumber || state.claimData.details.serialNumber,
                    },
                    damage: actionData?.luggage?.damages || state.claimData.damage,
                    contact: {
                        ...state.claimData.contact,
                        address: actionData?.location?.address || state.claimData.contact.address,
                        city: actionData?.location?.city || state.claimData.contact.city,
                        coords: actionData?.location?.coords ? {
                            lat: actionData?.location?.coords.lat,
                            lon: actionData?.location?.coords.lng
                        } :  state.claimData.contact.coords,
                        country:{
                            code: actionData?.location?.countryCode || state.claimData.contact.country.code,
                            name: actionData?.location?.country || state.claimData.contact.country.name
                        },
                        zipCode: actionData?.location?.zipCode || state.claimData.contact.zipCode,
                        travelling14days: !!actionData?.dateNextTravel
                    },
                    dateNextTravel: actionData?.dateNextTravel ? new Date(actionData?.dateNextTravel) : state.claimData.dateNextTravel
                }
            }

        case 'ADD_MISSIND_DOCUMENT': return {
            ...state,
            claimData: {
                ...state.claimData,
                missingDocuments: [
                    ...state.claimData.missingDocuments,
                    action.data
                ]
            }
        }

        case 'UPDATE_IMAGE_FROM_DRAFT': return {
            ...state,
            claimData: {
                ...state.claimData,
                missingDocuments: action.dataType.indexOf('|missingDocument') >=0 ?
                    state.claimData.missingDocuments.map(doc => {
                        if (doc.document.url === action.file.url){
                            return { ...doc, document: action.file };
                        }
                        return doc;
                    }): state.claimData.missingDocuments,
                photoDamages: action.dataType === 'damage' ?
                    state.claimData.photoDamages.map(file => {
                        if (file.url === action.file.url){
                            return action.file;
                        }
                        return file;
                    }) : state.claimData.photoDamages,
                photos: !(action.dataType.indexOf('|missingDocument') >=0 || action.dataType === 'damage') ?
                    {
                        ...state.claimData.photos,
                        [action.dataType]: action.file
                    } : state.claimData.photos
            }
        }

        case 'RUN_CHECK': return {
            ...state,
            runCheck: action.data !== undefined ? action.data : true
        }

        case 'REMOVE_DRAFT': return {
            ...state,
            drafts: state.drafts.filter(draft => draft.id !== action.draftId)
        }

        default: return state;
    }

}

export default Store;