import { call, put, takeLatest, select } from 'redux-saga/effects';
import api from 'api';
import fileSaver from 'file-saver';
import {
  SUBMIT_REVIEW,
  SubmitReviewT,
  increaseNotificationCount,
  SetDownloadPath,
  SET_DOWNLOAD_FILE
} from 'Store/modules/claim';
import {
  OpenClaimDetailsRequestT,
  OPEN_CLAIM_DETAILS_REQUEST,
  openClaimDetailsSuccess,
  openClaimDetailsFailure,
  DOWNLOAD_CLAIM_INFO_REQUEST,
  DownloadClaimInfoT,
  DOWNLOAD_CLAIM_INFO_SUCCESS,
  DOWNLOAD_CLAIM_INFO_FAILURE,
} from 'Store/modules/claimDetails';
import {
  WEBSOCKET_CLAIM_REPORT_RECEIVED,
  WEBSOCKET_CLAIM_VERDICT_RECEIVED,
  WEBSOCKET_NEW_STATUS_RECEIVED,
  WebsocketClaimReportReceivedT,
  WebsocketClaimVerdictReceivedT,
  WebsocketNewClaimStatusReceivedT,
} from 'Store/modules/websocket';
import type { AppStateT } from 'Store/modules';
import { markClaimToResolve } from '../modules/savedRating';
import { getFileData } from 'Helpers/files';


function* openClaimDetailsSaga({ id, isArchived }: OpenClaimDetailsRequestT) {
  try {

    const { data } = yield call(api().claim.getById, id, isArchived);
    yield put(openClaimDetailsSuccess(data));

  } catch (err) {
    yield put(openClaimDetailsFailure(err));
  }
}

function* setReviewSaga({ payload: { claimId, ...data } }: SubmitReviewT) {
  try {
    yield call(api().claim.review, claimId, data);
    yield put(markClaimToResolve(claimId, data.rating));
  } catch {
    console.log('error');
  }
}

function* increaseNotificationCountSaga(
  action:
    | WebsocketClaimVerdictReceivedT
    | WebsocketNewClaimStatusReceivedT
    | WebsocketClaimReportReceivedT,
) {
  const claimId = action.data.claimId;
  const currentClaimId = yield select(({ claimChat }: AppStateT) => claimChat.data.claim?.id);

  if (claimId !== currentClaimId) {
    yield put(increaseNotificationCount(claimId));
  }
}

function* setDownloadPath({ filePath }: SetDownloadPath) {
  try {
    const { data } = yield call(api().claim.downLoadFile, filePath);
    const fileData = getFileData(filePath);
    const file = new Blob([data], { type: fileData.mimeType });
    fileSaver.saveAs(file, fileData.fileName);
  } catch {
    console.log('error');
  }
}


function* downloadClaimInfoSaga({ id }: DownloadClaimInfoT) {
  try {
    const { data } = yield call(api().claim.download, id);
    const file = new Blob([data], { type: 'application/pdf' });
    fileSaver.saveAs(file, 'Claim Info.pdf');
    yield put({ type: DOWNLOAD_CLAIM_INFO_SUCCESS });
  } catch (error) {
    console.log('error', JSON.stringify(error));
    yield put({ type: DOWNLOAD_CLAIM_INFO_FAILURE });
  }
}

export default function* claim(): Generator {
  yield takeLatest(OPEN_CLAIM_DETAILS_REQUEST, openClaimDetailsSaga);
  yield takeLatest(SUBMIT_REVIEW, setReviewSaga);
  yield takeLatest(SET_DOWNLOAD_FILE, setDownloadPath);
  yield takeLatest(
    [
      WEBSOCKET_NEW_STATUS_RECEIVED,
      WEBSOCKET_CLAIM_VERDICT_RECEIVED,
      WEBSOCKET_CLAIM_REPORT_RECEIVED,
    ],
    increaseNotificationCountSaga,
  );
  yield takeLatest(DOWNLOAD_CLAIM_INFO_REQUEST, downloadClaimInfoSaga);
}
