import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StateT as UserStateT } from '../../Store/modules/user';
import { AppStateT } from '../../Store/modules';

interface IPrivateRoute {
  	children: React.ReactNode | React.ReactNode[];
}

export default function PrivateRoute({ children }: IPrivateRoute) {
	const { loggedIn } = useSelector<AppStateT, UserStateT>(({ user }) => user);
	const location = useLocation();

	if (!loggedIn) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <>{children}</>;
}