import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addCartProductSimple, CartProductT, ClientDataT, fetchConfigShop, fetchSaveCart, removeCartProduct, setConfigShop, setDeliveryData,  setShopClaim, ShopStateT } from 'Store/modules/luggageStore/shop';
import {
  LuggageDetailsMenu,
  LuggageGalleryCarousel,
  StoreDesktopMainScreen,
  VoucherBlock,
  ShoppingCartMobileCheckout,
} from 'PageComponents/LuggageStore';

import storeReducer, { actions as storeActions, storeInitialState } from './StoreModal.reducer';
import type { StateT as CatalogStateT } from 'Store/modules/luggageStore/catalog';
import type { LuggageProductsSimpleT, LuggageT } from 'api/modules/store';
import { fetchStoreCatalog } from 'Store/modules/luggageStore/catalog';
import { openClaimDetailsRequest } from 'Store/modules/claimDetails';
import type { LuggageStoreStateT } from 'Store/modules/luggageStore';
// import { ReactComponent as SearchIcon } from 'Assets/search.svg';
// import { ReactComponent as FilterIcon } from 'Assets/filter.svg';
import { LoadableContent} from 'Components';
import { getPropablyCountryNameByCode } from 'Helpers/strings';
import type { StateT as UserStateT } from 'Store/modules/user';
// import { ReactComponent as BackIcon } from 'Assets/back.svg';
import { maxProductsInShoppingCart } from "Helpers/rules";
import { ClaimDetailsT, ClaimT } from 'api/modules/claim';
import { changeStoreConfig } from 'Store/modules/page';
import { StoreStates } from './StoreModal.contants';
import { getDataCart } from 'Helpers/useDataCart';
import { runNotify } from 'Helpers/notifications';
import type { AppStateT } from 'Store/modules';
// import styles from './StoreModal.module.scss';
import usePrevious from 'Helpers/usePrevious';

let timeout: any = null;

interface IStoreModal {
  open: boolean;
  onClose: () => void;
  claim: ClaimT | null
}

// function getTitle(storeState: StoreStates, t : (data: string) => string) {
//   switch (storeState) {
//     case StoreStates.CHECKOUT: {
//       return t('shop.checkOut');
//     }
//     case StoreStates.LUGGAGE_DETAILS:
//     case StoreStates.LIST:
//     default: {
//       return t('shop.fixiStore');
//     }
//   }
// }

// interface IStoreModalTitle {
//   storeState: StoreStates;
//   openFilter: () => void;
//   searchIsOpen: boolean;
//   openSearchPanel: () => void;
//   closeSearchPanel: () => void;
//   searchPanelValue: string;
//   setSearchPanelValue: (value: string) => void;
//   resetState: () => void;
//   openCart: () => void;
//   cart: CartT;
//   searchAction?: () => void,
//   t: (data: string) => string,
//   onBlur?: () => void
// }

// function StoreModalTitle(props: IStoreModalTitle) {
//   const inDetails = props.storeState === StoreStates.LUGGAGE_DETAILS;
//   const { cart } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
//   const inCart = props.storeState === StoreStates.CHECKOUT;
//   const dispatch = useDispatch();

//   const control =
//     !inDetails && !inCart ? (
//       <>
//         <button className={styles.searchButton} onClick={props.openSearchPanel}>
//           <SearchIcon />
//         </button>

//         <button className={styles.filterButton} onClick={props.openFilter}>
//           <FilterIcon />
//         </button>
//       </>
//     ) : (
//       <button onClick={props.resetState} className={styles.backButton}>
//         <BackIcon />
//       </button>
//     );

//   return (
//     <div className={styles.titleRoot}>
//       {props.searchIsOpen ? (
//         <SearchInput
//           value={props.searchPanelValue}
//           onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
//             props.setSearchPanelValue(evt.target.value);
//           }}
//           clear={() => props.setSearchPanelValue('')}
//           onClickOutside={() => {
//             props.closeSearchPanel();
//           }}
//           close={() => null}
//           onEnter={() => {
//             dispatch(setProductsLimit(initialState.productsLimit));
//             props.searchAction?.();
//           }}
//           onBlur={props?.onBlur}
//         />
//       ) : (
//         <>
//           {control}

//           <span className={styles.title}>{getTitle(props.storeState, props.t)}</span>

//           {!inCart && (
//             <CartButton
//               onClick={props.openCart}
//               className={styles.cartButton}
//               inCart={cart.products.reduce((acc: number, product: CartProductT) => acc + product.quantity, 0)}
//               t={props.t}
//             />
//           )}
//         </>
//       )}
//     </div>
//   );
// }

interface IStoreMobileMainScreen {
  onItemSelect: (luggageId: number) => void;
  catalog: LuggageStoreStateT['catalog'];
}

function StoreMobileMainScreen(props: IStoreMobileMainScreen) {

  return (
    <>
      <VoucherBlock onClick={() => {}} />

      <StoreDesktopMainScreen
        sliderConfig={{
          width: 167,
          height: 264,
          visibleSlides: 1.7,
          arrows: false,
          isIntrinsicHeight: true,
        }}
        onItemSelect={props.onItemSelect}
        catalog={props.catalog.data}
        mobile
      />
    </>
  );
}

interface ILuggageDetailsMobile {
  loading: boolean;
  luggage: LuggageT | null;
  addToCart: (productSimple: LuggageProductsSimpleT | null, data: LuggageT) => void;
  changeSize?: (luggageId: number) => void,
  productSimpleId?: string
}

function LuggageDetailsMobile(props: ILuggageDetailsMobile) {
  
  return (
    <LoadableContent loading={props.loading}>
      {props.luggage && (
        <div>
          <LuggageGalleryCarousel luggage={props.luggage} />
          <LuggageDetailsMenu luggage={props.luggage} addToCart={props.addToCart} mobile changeSize={props.changeSize} productSimpleId={props.productSimpleId} />
        </div>
      )}
    </LoadableContent>
  );
}

let timeoutSearch: any  = null;

export default function StoreModal(props: IStoreModal) {
    const dispatch = useDispatch();
    const [storeState, storeStateDispatch] = useReducer(storeReducer, storeInitialState);
    const claimsArchived = useSelector<AppStateT, boolean>((state) => state.page.claimsArchived);
    const { catalog } = useSelector<AppStateT, Pick<LuggageStoreStateT, 'catalog' | 'details'>>(({ store }) => ({
        catalog: store.catalog,
        details: store.details,
    }));
    const { data: dataCatalog, dataIndexed: catalogIndexed } = useSelector<AppStateT, CatalogStateT>(({ store }) => store.catalog);
    const { cart, filters, configShop, voucher, delivery, changeShopCounter, termsAccept, rabatCode, note } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const claim = useSelector<AppStateT, ClaimDetailsT | null>((state) => state.claimDetails.data );
    const [luggage, setLuggage] = useState<LuggageT|null>();
    const [productSimpleId, setProductSimpleId] = useState<string|null>(null);
    const _changeShopCounter = usePrevious(changeShopCounter);
    const [readyCheckCart, setReadyCheckCart] = useState(false);
    const { t } = useTranslation();
    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);

    useEffect(() => {
      if (props.open){
        storeStateDispatch(storeActions.setSearchPanelValue(filters.search));
      }
    }, [props.open, filters.search, dispatch]);

    useEffect(() => {
      if (user && claim?.location){
          const savedDelivery: { clientAddress: ClientDataT, clientAddressDelivery: ClientDataT } = {
            clientAddress: {
              name: user?.firstName || '',
              surname: user?.lastName || '',
              email: user?.email?.replace(' ', '').toLowerCase() || '',
              countryCode: claim.location.countryCode || 'PL',
              country: claim.location.country || getPropablyCountryNameByCode(user?.location.countryCode || 'PL'),
              phoneNumber: user?.phoneNumber || '',
              street: claim.location.address,
              city: claim.location.city,
              zipCode: claim.location.zipCode,
              apt: '',
              companyName: '',
              companyTaxNumber: ''
            },
            clientAddressDelivery: {
              name: user?.firstName || '',
              surname: user?.lastName || '',
              email: user?.email?.replace(' ', '').toLowerCase() || '',
              countryCode: claim.location.countryCode || 'PL',
              country: claim.location.country || getPropablyCountryNameByCode(user?.location.countryCode || 'PL'),
              phoneNumber: user?.phoneNumber || '',
              street: claim.location.address,
              city: claim.location.city,
              zipCode: claim.location.zipCode,
              apt: '',
              companyName: '',
              companyTaxNumber: ''
            },
          };

          dispatch(setDeliveryData(savedDelivery.clientAddress, savedDelivery.clientAddressDelivery));
      }
  }, [user, dispatch, claim?.location]);

  // Zapis stanu koszyka do bazy
  useEffect(() => {
    if (readyCheckCart && claim?.id && voucher > 0 && delivery.deliveryMethod && _changeShopCounter !== changeShopCounter){
        dispatch(fetchSaveCart(claim?.id, getDataCart(claim, rabatCode, voucher, cart, delivery, termsAccept, configShop, note)));
    }
  }, [dispatch, cart, voucher, rabatCode, claim, delivery, termsAccept, _changeShopCounter, changeShopCounter, readyCheckCart, configShop, note]);

  // pobieranie danych o kolorach ze wszystkich w produktów simple
  useEffect(() => {
    if (props.open && configShop.colors.length === 0){
      let Colors: any = [];
      dataCatalog.forEach((cat: LuggageT) => {
        if (cat.extension_attributes.simple_products){
            cat?.extension_attributes?.simple_products.filter((product) => product.color?.type === 1 && product.color?.group_swatch_value).forEach((product: LuggageProductsSimpleT) => {
                if (!Colors.some((c: any) => c.value === product.color?.group_swatch_value)){
                    const _color = { 
                        title: product.color.group_value, 
                        name: product.color.group_value, 
                        value: product.color?.group_swatch_value
                    };
                    Colors.push(_color);
                }
            });
        }
      });
      if (Colors.length > 0){
        dispatch(setConfigShop({...configShop, colors: Colors}));
      }
    }
  }, [dataCatalog, props.open, configShop, dispatch]);


  useEffect(() => {
    if (props.open){
      timeout = setTimeout(() => {
        setReadyCheckCart(true);
      }, 500);
    } else {
      clearTimeout(timeout);
      clearTimeout(timeoutSearch);
      setReadyCheckCart(false);
    }
  }, [props.open]);

    // Ustawienie vouchera
    useEffect(() => {
        dispatch(setShopClaim(claim));
    }, [dispatch, claim, props.claim?.id]);

    useEffect(() => {
        if (props.claim?.id){
          dispatch(fetchConfigShop(String(props.claim?.id)));
        }
    }, [dispatch, props.claim?.id]);


    // Wczytanie danych claima z endpointu
    useEffect(() => {
        if (props.open && props.claim?.id){
            dispatch(openClaimDetailsRequest(props.claim.id, claimsArchived));
        }
        dispatch(changeStoreConfig(props.claim?.id ? { ...configShop, claimId: props.claim.id } : null));
    }, [dispatch, props.claim?.id, props.open, configShop, claimsArchived]);

    useEffect(() => {
        if (claim?.id){
          dispatch(fetchStoreCatalog(claim?.id as string, configShop.storeView as string));
        }
    }, [dispatch, configShop.storeView, claim?.id]);

    function onItemSelect(simpleProductId: number) {
        storeStateDispatch(storeActions.setStoreState(StoreStates.LUGGAGE_DETAILS));
        const luggage = catalog.data.find((data: LuggageT) => data.extension_attributes.simple_products.some(sp => sp.id === simpleProductId));
        if (luggage){
          const ps = luggage.extension_attributes.simple_products.find(sp => sp.id === simpleProductId);
          setProductSimpleId(ps ? (ps?.id || '') as string : undefined)
          setLuggage(luggage);
        }
    }

    function resetState() {
        storeStateDispatch(storeActions.resetState());
    }

    // function openFilter() {
    //     storeStateDispatch(storeActions.setFiltersIsOpen(true));
    // }

    // function closeFilter() {
    //     storeStateDispatch(storeActions.setFiltersIsOpen(false));
    // }

    // function openSearchPanel() {
    //     storeStateDispatch(storeActions.setSearchIsOpen(true));
    // }

    // function closeSearchPanel() {
    //     dispatch(setShopFilters({
    //         ...filters,
    //         search: ''
    //     }));
    //     storeStateDispatch(storeActions.setSearchIsOpen(false));
    //     dispatch(setProductsLimit(initialState.productsLimit));
    // }

    // function setSearchPanelValue(value: string) {
    //     storeStateDispatch(storeActions.setSearchPanelValue(value));
    //     clearTimeout(timeout);
    //     clearTimeout(timeoutSearch);
    //     timeoutSearch = setTimeout(() => {
    //       dispatch(setShopFilters({
    //           ...initialsFilters,
    //           search: value
    //       }));
    //     }, 1000);
    // }

    // const searchAction = () => {
    //     clearTimeout(timeoutSearch);
    //     dispatch(clearMostPopular());
    //     dispatch(setShopFilters({
    //         ...initialsFilters,
    //         showListPriceWithVoucher: filters.showListPriceWithVoucher,
    //         search: storeState.searchPanelValue
    //     }));
    //     dispatch(setShopFiltersLocal(initialsFilters));
    //     storeStateDispatch(storeActions.setSearchIsOpen(false));
    // }

    const  openCart = () => {
        storeStateDispatch(storeActions.setStoreState(StoreStates.CHECKOUT));
    }

    const addToCart = useCallback((productSimple: LuggageProductsSimpleT | null, data: LuggageT) => {
    const countProducts = cart.products.reduce((accu, data) => accu + data.quantity, 0);
      if (countProducts >= maxProductsInShoppingCart){
          return runNotify({ message: t('shop.fullCartWarning'), type: 'error' });
      }
      if (productSimple){
            dispatch(addCartProductSimple(productSimple, data));
            openCart();
            runNotify({ message: t('shop.addCart', { productName: `<strong>${productSimple.name}</strong>` }), type: 'success' });
      }
    }, [dispatch, t, cart.products]);

    const removeFromCart = useCallback((data: CartProductT) => {
        const product = catalogIndexed[data.id];
        const quantityCartProducts = cart.products.reduce((acc, product: CartProductT)=> acc + product.quantity, 0);
        dispatch(removeCartProduct(data));
        runNotify({ message: t('shop.removeCart', { productName: `<strong>${product.name}</strong>` }), type: 'error' });
        if (quantityCartProducts <= 1 && claim){
            resetState();
            runNotify({ message: t('shop.emptyCart'), type: 'error'});
        }
    }, [dispatch, cart.products, claim, t, catalogIndexed]);

    function renderContent() {
        switch (storeState.state) {
          case StoreStates.LUGGAGE_DETAILS: {
                return (
                    <LuggageDetailsMobile
                        luggage={luggage||null}
                        loading={false}
                        addToCart={addToCart}
                        changeSize={onItemSelect}
                        productSimpleId={productSimpleId}
                    />
                );
            }
            case StoreStates.CHECKOUT: {
                if (catalog) {
                    return (
                        <>
                            <ShoppingCartMobileCheckout
                                cart={cart}
                                voucherValue={100}
                                removeFromCart={removeFromCart}
                                closeCart={resetState}
                                onItemSelect={onItemSelect}
                                claimId={String(props?.claim?.id)}
                                closeShop={props.onClose}
                            />
                        </>
                    );
                }
                return null;
            }
            case StoreStates.LIST:
                default: {
                    return (
                        <>
                            <StoreMobileMainScreen onItemSelect={onItemSelect} catalog={catalog} />
                        </>
                    )
            }
        }
    }

    useEffect(() => {
        if (!props.open) {
            resetState();
        }
    }, [props.open]);

    return (
        // <Modal
        //     open={props.open}
        //     onClose={props.onClose}
        //     title={
        //         <StoreModalTitle
        //             storeState={storeState.state}
        //             openFilter={openFilter}
        //             searchIsOpen={storeState.searchIsOpen}
        //             openSearchPanel={openSearchPanel}
        //             closeSearchPanel={closeSearchPanel}
        //             searchPanelValue={storeState.searchPanelValue}
        //             setSearchPanelValue={setSearchPanelValue}
        //             resetState={resetState}
        //             openCart={openCart}
        //             cart={cart}
        //             searchAction={searchAction}
        //             t={t}
        //             onBlur={searchAction}
        //         />
        //     }
        // >
        <>
            {renderContent()}
{/* 
            <Filters open={storeState.filtersIsOpen} onClose={closeFilter} catalog={catalog} /> */}
        </>
    
    );
}
