import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
// import * as Sentry from "@sentry/react";
import parse from 'html-react-parser';

import { ReactComponent as Arrow } from 'Assets/right-arrow.svg';

import { changeShopCounter, clearCart, clearPayment, DeliveryMethodT, DeliveryOrderT, setAgreeTerms, setDelivery, setNote, setValidForm, ShopStateT, showModalOrderSuccess } from 'Store/modules/luggageStore/shop';
import { setUserCoords, StateT as UserStateT } from 'Store/modules/user';
import { changeModalArticle, StoreConfigT } from 'Store/modules/page';
import { DeliveryOptionsDesktop } from 'PageComponents/LuggageStore';
// import ModalRedirect from "Components/ModalRedirect/ModalRedirect";
import { LuggageStoreStateT } from "Store/modules/luggageStore";
import { checkValidForm, validField } from 'Helpers/validForm';
import { PaymentsE} from "Store/Types/order";
import styles from './ShoppingCartMainScreen.module.scss';
// import ModalError from 'Components/ModalError/ModalError';
import runApi, { ResultApiTypeT } from 'api/base/runApi';
import { PaymentStatusesE } from 'Store/Enums/Payment';
import { shopRequireNipCountrys } from "Helpers/rules";
import ClientAdress from './ClientAdress/ClientAdress';
import Textarea from 'Components/Textarea/Textarea';
import Checkbox from 'Components/Checkbox/Checkbox';
import { getDataOrder } from 'Helpers/useDataCart';
import { runNotify } from 'Helpers/notifications';
import Loading from 'Components/Loading/Loading';
import { ErrorsEnumE } from "Store/Enums/errors";
import { calculateCart } from 'Helpers/store';
import { Button, Switcher } from 'Components';
import Payments from './Payments/Payments';
import { AppStateT } from 'Store/modules';

function ShoppingCartMainScreen() {
    const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
    const [sendingOrder, setSendingOrder] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const { delivery, termsAccept, validForm, cart, rabatCode, voucher, claim, note, configShop } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const storeConfig = useSelector<AppStateT, StoreConfigT>((state) => state.page.storeConfig);
    const { data: userData } = useSelector<AppStateT, UserStateT>(({ user }) => user);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [focusValue, setFocusValue] = useState('');
    const [focusValueDelivery, setFocusValueDelivery] = useState('');
    const [noteValueOnFocus, setNoteValueOnFocus] = useState('');
    const [showNote, setShowNote] = useState(!!note);
    const [firstShowCounter, setFirstShowCounter] = useState(false);
    // const [errorModal, setErrorModal] = useState<string|null>(null);
    // const [redirect, setRedirect] = useState<RedirectDataT>({ type: null, url: null });
    const { catalog } = useSelector<AppStateT, Pick<LuggageStoreStateT, 'catalog'>>(({ store }) => ({ catalog: store.catalog }));

    useLayoutEffect(() => {
        const elementTerms = document.getElementById('termsLabelCheckbox');
        if (elementTerms){
            const actionEvent = (event) => {
                event.stopPropagation();
                dispatch(changeModalArticle({ pagesContentKey: 'terms' }));
            }

            elementTerms.addEventListener('click', actionEvent);

            return () => {
                elementTerms.removeEventListener('click', actionEvent);
            }
        }
    }, [dispatch]);
    
    useEffect(() => {
        const handleGeoSuccess = (position: any) => {
            const { latitude, longitude } = position.coords;
            if (typeof latitude === 'number' && typeof longitude === 'number') {
                dispatch(setUserCoords({ lat: latitude, lng: longitude }))
            }
        }
        const handleGeoError = (error: any) => console.warn('Geolocation error:', error.message)

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(handleGeoSuccess, handleGeoError, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            })
        } else {
            console.warn('Geolocation is not supported by this browser.')
        }
        
    }, [dispatch]);

    useEffect(() => {
        if (note && !firstShowCounter){
            setShowNote(true);
            setFirstShowCounter(true);
        }
    }, [note, firstShowCounter]);

    useEffect(() => {
        if (totalAmount <=0 ){
            dispatch(clearPayment());
        }
    }, [dispatch, totalAmount]);

    useEffect(() => {
        checkValidForm(dispatch, cart, termsAccept, setValidForm, delivery, totalAmount);
    }, [termsAccept, delivery, cart, dispatch, totalAmount]);

    useEffect(() => {
        const amountsData = calculateCart(cart, voucher, rabatCode, 0, configShop?.paymentSkipThreshold?.amount);
        const totalValue = amountsData.isTotalSpecial ? 0 : amountsData.total;
        setTotalAmount(totalValue);
    }, [cart, voucher, rabatCode, configShop?.paymentSkipThreshold?.amount]);

    const actionChange = useCallback((type: string, data: string, addressType: string = 'clientAddress') => {
        dispatch(setDelivery({
            ...delivery,
            [addressType]: {
                ...delivery.clientAddress,
                [type]: data
            }
        }));
    }, [dispatch, delivery]);

    const actionDeliveryChange = useCallback((type: string, data: string, addressType: string = 'clientAddress') => {
        dispatch(setDelivery({
            ...delivery,
            [addressType]: {
                ...delivery.clientAddressDelivery,
                [type]: data
            }
        }));
    }, [dispatch, delivery]);

    const setDeliveryMethod = useCallback((activeDeliveryOption: DeliveryMethodT, deliverySms: boolean) => {
        dispatch(setDelivery({
            ...delivery,
            deliveryMethod: activeDeliveryOption,
            deliverySms
        }));
        setShowDeliveryOptions(false);
        dispatch(changeShopCounter());
    }, [dispatch, delivery])

    const actionSetReg = useCallback(() => {
        dispatch(setAgreeTerms(!termsAccept));
        dispatch(changeShopCounter());
    }, [dispatch, termsAccept]);

    const submitAction = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        // if (!validForm || !claim) return;

        const correctDelivery: DeliveryOrderT = {
            ...delivery,
            deliveryMethod: {
                ...delivery.deliveryMethod,
                currency: {
                    code: storeConfig.currency.code,
                    symbol: storeConfig.currency.symbol,
                }
            },
            clientAddress: {
                ...delivery.clientAddress,
                country: delivery.clientAddress.country || (claim?.location.country || '')
            },
            clientAddressDelivery: {
                ...delivery.clientAddressDelivery,
                country: delivery.clientAddressDelivery.country || (claim?.location.country || '')
            },
        };

        const dataSend = getDataOrder(rabatCode, voucher, cart, correctDelivery, userData, storeConfig, note, catalog.data);
        setSendingOrder(true);
        runApi(`claim/${claim.id}/shop/order`, dataSend, (r: ResultApiTypeT) => {
            setSendingOrder(false);
            if (r.result){
                if (!dataSend.payment || r.data?.payment?.status === PaymentStatusesE.COMPLETED || (r.data?.payment?.status === PaymentStatusesE.PROCESSING && r.data.payment.selectedType === PaymentsE.WIRE_TRANSFER && r.data.status === 'DRAFT')){
                    dispatch(clearCart(true));
                    dispatch(showModalOrderSuccess(true));
                    navigate(`/store/${claim?.id}`);
                } else if (r.data?.payment?.status === PaymentStatusesE.FAILED){
                    // setErrorModal(r.data.payment?.internalError?.message || 'Error while payment...');
                    // Sentry.withScope(scope => {
                    //     scope.setTag("erro_message", r.data.payment?.internalError?.message || 'Error while payment...');
                    //     scope.setExtra("title", r.data?.payment?.internalError?.name);
                    //     Sentry.captureMessage( 'Order Payment Error', "error");
                    // });

                } else if (r.data?.payment?.status === PaymentStatusesE.REDIRECT){
                    // setRedirect({
                    //     type: dataSend.payment?.paypal ? 'paypal' : 'card',
                    //     url: r.data.payment.redirectUrl
                    // });
                } else {
                    // setErrorModal(r.data.payment.message || 'Error while payment...');
                }
            } else {
                if ((r.error?.code as string) === ErrorsEnumE.ORDER_ALREADY_CREATED){
                    // setRedirect({
                    //     type: 'orderAlready',
                    //     url: `/${claim.id}`,
                    //     buttonLabel: t('shop.orderExistButton'),
                    //     content: contentOrderExist()
                    // });

                } else {
                    let _error = t('shop.errorPayment') || 'Error while payment...';
                    if ((r.error?.code as string) === ErrorsEnumE.CLAIM_IS_BLOCKED){
                        _error = t('caseStatusesHeaders.BLOCKED');
                    }
                    runNotify({ message: _error, type: 'error' });
                }
            }
        });
        
    }

    const blurActionPayment = (type: string) => {
        if (focusValue !== delivery.clientAddress[type]){
            dispatch(changeShopCounter());
        }
    }

    const blurActionPaymentDelivery = (type: string) => {
        if (focusValueDelivery !== delivery.clientAddressDelivery[type]){
            dispatch(changeShopCounter());
        }
    }

    const actionCopyDataOrigin = () => {
        dispatch(setDelivery({
            ...delivery,
            clientAddressDelivery: {
                ...delivery.clientAddress,
            }
        }));
        runNotify({ message: t('shop.buttonCopyContactSuccess'), type: 'success' });
    }

    // const contentOrderExist = useMemo(() => (): JSX.Element => {
    //     return (
    //         <div>
    //             <p>{t('shop.dearClient')}, </p>
    //             <p>{parse(t('shop.orderExistContent', { caseNumber: `<strong>${claim?.caseNumber || ''}</strong>` }))}</p>
    //         </div>
    //     )
    // }, [claim?.caseNumber, t]);

    const getDeliveryName = useMemo(() => (name: string): string => {
        const translateName = t(`shop.${name}`);
        if (translateName !== name) return translateName;
        return name;
    }, [t]);

    return (
        <>
            <div className={styles.root}>
                <form onSubmit={submitAction}>
                    <div className={styles.title}>{t('shop.formDelivery')}</div>
                    <ClientAdress
                        actionChange={actionChange}
                        clientData={delivery.clientAddress}
                        clientAddressType={'clientAddress'}
                        validField={validField}
                        countryName={claim?.location.country}
                        blurAction={blurActionPayment}
                        t={t}
                        setFocusValue={setFocusValue}
                        requireNip={shopRequireNipCountrys.includes(delivery.clientAddress.countryCode)}
                    />
                    <div className={styles.chooseAddressContainer}>
                        <div className={styles.theSameAdrdessText}>
                            {t('shop.formSameAddress')}
                        </div>

                        <div className={styles.switcher}>
                            <Switcher
                                name="deliveryBillingTheSame"
                                value={delivery.clientAddressDeliveryOn}
                                onChange={() => {
                                    dispatch(setDelivery({ ...delivery, clientAddressDeliveryOn: !delivery.clientAddressDeliveryOn }))
                                    dispatch(changeShopCounter());
                                }}
                            />
                        </div>
                    </div>
                    {!delivery.clientAddressDeliveryOn &&
                        <ClientAdress
                            actionChange={actionDeliveryChange}
                            clientData={delivery.clientAddressDelivery}
                            clientAddressType={'clientAddressDelivery'}
                            validField={validField}
                            countryName={claim?.location.country}
                            blurAction={blurActionPaymentDelivery}
                            t={t}
                            shipping
                            actionCopyDataOrigin={actionCopyDataOrigin}
                            setFocusValue={setFocusValueDelivery}
                            requireNip={shopRequireNipCountrys.includes(delivery.clientAddressDelivery.countryCode)}
                        />
                    }
                    <div className={styles.title}>{t('shop.formDeliveryMethod')}</div>

                    <div className={styles.deliveryMethod} onClick={() => setShowDeliveryOptions(true)}>
                        <div className={styles.titleDelivery}>{t('shop.formDeliveryOptions')}</div>
                        <div className={styles.name}>{getDeliveryName(delivery.deliveryMethod.name)} - {delivery.deliveryMethod.value} {configShop.currency.code}</div>
                        <Arrow className={styles.arrow} />
                    </div>

                    <div className={styles.notes}>
                        {!showNote ?
                            <div onClick={() => setShowNote(true)} className={styles.titleShow}>{t('shop.messageCourier')}</div>:
                            <div className={styles.notesArea}>
                                <Textarea
                                    label={t('shop.messageCourier')}
                                    onChangeText={(value) => dispatch(setNote(value))}
                                    value={note}
                                    className={styles.textarea}
                                    maxLength={50}
                                    onBlur={() => {
                                        if (noteValueOnFocus !== note){
                                            dispatch(changeShopCounter());
                                        }
                                    }}
                                    onFocus={() => setNoteValueOnFocus(note)}
                                />
                            </div>
                        }
                    </div>

                    {((totalAmount > 0 && cart.products.length > 0) || (totalAmount === 0 && cart.products.length === 0)) &&
                        <>
                            <div className={styles.title}>{t('shop.formPaymentMethod')}</div>
                            <Payments validField={validField} cartAmount={totalAmount} />
                        </>
                    }

                    <div className={styles.regLayer} onClick={actionSetReg}>
                        <Checkbox checked={termsAccept} name={'Reg2'} />
                        <span>
                            {parse(t('shop.formGeneral', { terms: `<label class="terms" id="termsLabelCheckbox">${t('footer.termsAndConditions')}</label>` }))}
                        </span>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button type="submit" className={styles.btn} disabled={!validForm || sendingOrder}>
                            {t('shop.formPlaceOrder')}
                        </Button>
                    </div>

                </form>
            </div>
            {sendingOrder &&
                <Loading backdrop subtitle={t('shop.sendingOrder')} />
            }
            <DeliveryOptionsDesktop
                setDeliveryMethod={setDeliveryMethod}
                show={showDeliveryOptions}
                currentValue={delivery.deliveryMethod}
                onClose={() => setShowDeliveryOptions(false)}
                sms={delivery.deliverySms}
            />
            {/* <ModalError
                actionClose={() => setErrorModal(null)}
                open={!!!errorModal}
                t={t}
                title={errorModal}
            /> */}
            {/* {redirect.url &&
                <ModalRedirect
                    open
                    content={redirect.content || t('shop.redirectContent')}
                    url={redirect.url}
                    size={redirect.type === 'orderAlready' ? 'big' : 'small'}
                    seconds={redirect.type === 'orderAlready' ? 60 : 30}
                    actionAfterRedirect={() => {
                        dispatch(clearCart(true));
                        navigate(`/${claim?.id}`);
                    }}
                    buttonSend={redirect.buttonLabel || t('shop.redirectButtonSend')}
                />
            } */}
        </>
    );
}

export default ShoppingCartMainScreen;
