import React, { MouseEvent, useEffect, useState } from 'react';

import CircularTimer from "Components/CircularTimer/CircularTimer";
import styles from './Button.module.scss';
import cx from 'classnames';
import { useDeviceType } from 'Helpers/responsiveContainers';

export type MainPropsButtonT = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (evt: MouseEvent<HTMLButtonElement>) => void;
  color?: 'primary' | 'secondary';
  children: React.ReactNode | string;
  className?: string;
  disabled?: boolean;
  updates?: number;
  form?: string;
  block?: boolean,
  timer?: number,
  onTickInfo?: boolean,
  id?: string,
  onTimerEnd?: () => void
}

const Button: React.FC<MainPropsButtonT> = ({ children, block, className, color = 'primary', disabled, form, onClick, timer, type = 'button', updates, onTickInfo, onTimerEnd, id }) => {

	const [tick, setTick] = useState(timer || 0);
	const [showTimer, setShowTimer] = useState(timer > 0 && tick > 0);
    const { isMobile } = useDeviceType();
	
	useEffect(() => {
		setShowTimer(timer > 0 && tick > 0);
	}, [tick, timer]);

	return (
		<button
			className={cx(styles.root, { [styles.isMobileFont]: isMobile }, className, {
				[styles.primary]: color === 'primary',
				[styles.secondary]: color === 'secondary',
				[styles.block]: !!block,
				[styles.showTimer]: showTimer
			})}
			onClick={onClick}
			disabled={disabled || showTimer}
			type={type}
			form={form}
			id={id}
		>
			{showTimer &&
				<div className={styles.progressCircle}>
					<CircularTimer
						limit={timer}
						strokeWidth={15}
						trailColor={'#ffffff'}
						onTick={v => {
							setTimeout(() => {
								setTick(v);
								setShowTimer(timer > 0 && v > 0);
							}, 100);
						}}
						onTimeOut={() => {
							setTick(0);
							onTimerEnd?.();
						}}
					/>
				</div>
			}
			{children}
			{(onTickInfo && tick > 0) && <>{' '}({tick})</>}

			{!!updates && <span className={styles.updates}>{updates}</span>}
		</button>
	)
};

export default React.memo(Button);
