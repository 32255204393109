import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "rsuite";
import { AppStateT } from "Store/modules";
import { setModalZIndex, incrementModalCount, decrementModalCount} from "Store/modules/page";
import styles from './CustomModal.module.scss'

interface CustomModalProps {
  open: boolean;
  onClose?: () => void; 
  children: ReactNode; 
}

const CustomModal: React.FC<CustomModalProps> = ({ open, onClose, children }) => {
    const dispatch = useDispatch();
    const zIndex = useSelector<AppStateT, number>((state) => state.page.zIndex);
    const modalCount = useSelector<AppStateT, number>((state) => state.page.modalCount);
  
    useEffect(() => {
        if (open) {
          dispatch(incrementModalCount());
          dispatch(setModalZIndex((modalCount + 1)));
        }
        return () => {
          if (open) {
            dispatch(decrementModalCount());
          }
        };
    }, [open, dispatch, modalCount]);
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{ zIndex: zIndex }} 
        backdropStyle={{ zIndex: zIndex }}
        className={styles.customModalWidth}
      >
        {children}
      </Modal>
    );
  };
  
  export default CustomModal;