import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppStateT } from 'Store/modules';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Lottie from 'react-lottie';
import cx from 'classnames';

import { ReactComponent as IconPhone } from 'Assets/contact_phone.svg';
import { ReactComponent as IconEmail } from 'Assets/contact_email.svg';
import { ReactComponent as IconHome } from 'Assets/contact_home.svg';
import { ReactComponent as IconWeb } from 'Assets/contact_web.svg';
import technicalAnimation from 'Assets/lotties/technical.json';
import { ReactComponent as OkIcon } from 'Assets/pdf.svg';

import runApi, { ApiSendMethods, ClaimCreateSendT, ResultApiTypeT, sendDataClaim } from 'api/base/runApi';
import { ContactBranchT, PageConfigT, setReloadClaims } from 'Store/modules/page';
import { setUserData, type StateT as UserStateT } from 'Store/modules/user';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import ModalNextTag from './ModalNextTag/ModalNextTag';
import { StateLocalT } from '../../CreateForm';
import { validField } from 'Helpers/validForm';
import { Button, Loader } from 'Components';
import style from './Finish.module.scss';
import { getUrlOfImage} from 'services/api';
import { runNotify } from 'Helpers/notifications';

type MainPropsT = {
    state: StateLocalT,
    actionNewClaim: () => void,
    mobile: boolean,
    actionFinished: (data: any) => void,
    updateState: (dataType: string, data: any) => void,
    actionHideAvatar: (value: boolean) => void,
    actionNextTags: (data: StateLocalT['tagNumbersAccepted'], checkFoundBags?: boolean) => void
}

const defaultState: ResultDataT = {
    status: 0,
    errorText: '',
    sending: true,
    caseData: null,
    help: null,
    sendCounter: 0
}

type ResultDataT = {
    status: 0 | 1 | 2,
    errorText: string,
    sending: boolean,
    caseData: any,
    help: StateLocalT['branchContact'] | null,
    sendCounter: number
}

type NextActionT = 'single' | 'next' | 'finish';
const intervalSecondsTrySend = 30;

const Finish: React.FC<MainPropsT> = ({ state, actionNewClaim, mobile, actionFinished, updateState, actionHideAvatar, actionNextTags }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [stateLocal, setStateLocal] = useState(defaultState);
    const [urlKey, setUrlKey] = useState('');
    const [statusNextAction, setStatusNextAction] = useState<NextActionT>('finish');
    const [showInfoNextStep, setShowInfoNextStep] = useState(false);
    const [tagNumberPrepare, setTagNumberPrepare] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number|null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [timerCounterTrySend, setTimerCounterTrySend] = useState(0);

    const formatPrice = useFormattedPrice(state.wtBag.currencyIsoCode, pageConfig.noFractionCurrencies);

    const getContactBranch = useCallback((): ContactBranchT | null => {
        if (pageConfig.branchesContactsByLocation?.[state.claimData.contact.country.code]) {
            return pageConfig.branchesContactsByLocation[state.claimData.contact.country.code];
        }
        if (!state.branchContact && pageConfig.branchesContacts?.DEFAULT) {
            return pageConfig.branchesContacts.DEFAULT;
        }
        return state?.branchContact;
    }, [pageConfig.branchesContactsByLocation, pageConfig.branchesContacts, state.branchContact, state.claimData.contact.country.code])

    const updateUserData = useCallback(() => {
        runApi("user/me", {}, (r: any) => {
            if (r.result) {
                dispatch(setUserData(r.data));
            }
        }, ApiSendMethods.get);
    }, [dispatch])

    const sendClaim = useCallback(async (sendCounter: number) => {

        if (!actionFinished) return false;

        const tagNumber = state.tagNumbersAccepted.length > 0 && state.tagNumbersAccepted.some(t => t.status === 'processing') ? state.tagNumbersAccepted.find(t => t.status === 'processing').tagNumber : '';
        const sendData: ClaimCreateSendT = {
            ...state.claimData,
            tagNumber,
            wtValue: +formatPrice(state.wtBag.value),
            wtPurchaseDate: state.wtBag.purchaseDate,
            wtCurrencyIsoCode: state.wtBag.currencyIsoCode,
            fk_caseInfo_bag_id: state.fk_caseInfo_bag_id
        }
        setTagNumberPrepare(tagNumber);

        updateState('loading', true);
        sendDataClaim(sendData, sendCounter, (response: ResultApiTypeT) => {
            actionHideAvatar(true);
            actionFinished(true);
            updateState('loading', false);
            if (!response.result) {
                setErrorCode(response.error.dynamicCode);
                setTimerCounterTrySend(intervalSecondsTrySend);
                setStateLocal(oldState => {
                    return {
                        ...oldState,
                        sending: false,
                        status: 1,
                        help: getContactBranch() as any
                    }
                });
            } else {
                updateState('lastAddClaimAutomatic', !!response.data?.automaticResolution);
                setStateLocal(oldState => {
                    return {
                        ...oldState,
                        sending: false,
                        status: 2,
                        caseData: {
                            ...oldState,
                            caseNumber: response.data.caseNumber
                        },
                        help: null
                    }
                });
                if (response.data?.pdfRaport?.url) {
                    setUrlKey(response.data.pdfRaport.key);
                }
                dispatch(setReloadClaims());
                updateUserData();  
                if (state.tagNumbersAccepted.length > 0){
                    let foundWaiting = false;
                    const tagNumbersAccepted: StateLocalT['tagNumbersAccepted'] = state.tagNumbersAccepted.map(tag => {
                        if (tag.id === state.fk_caseInfo_bag_id){
                            tag.status = 'done';
                        }
                        if (!foundWaiting && tag.status === 'waiting') {
                            tag.status = 'processing';
                            foundWaiting = true;
                            updateState('fk_caseInfo_bag_id', tag.id);
                        }
                        return tag;
                    });
                    actionNextTags(tagNumbersAccepted);
                    if (tagNumbersAccepted.some(t => t.status === 'processing')) {
                        setStatusNextAction('next');
                        setShowInfoNextStep(true);
                    } else {
                        updateState('lastAddClaimId', response.data.id);
                    }
                } else {
                    updateState('lastAddClaimId', response.data.id);
                    setStatusNextAction('single');
                }
            }
        }, state.requiredMissingDocumentsCheck);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.requiredMissingDocuments, state.claimData, dispatch]);

    useEffect(() => {
        sendClaim(stateLocal.sendCounter);
    }, [state.claimData, sendClaim, stateLocal.sendCounter]);

    const actionDownloadPdf = useCallback(() => {
        getUrlOfImage(urlKey, (result, url)=>{
            if(result){
                window.open(url);
            }
            else{
                runNotify({ message: t('cashRefund.somethingWendWrong'), type: 'error' });
            }
        });
    }, [urlKey, t])

    const actionSendAgain = () => {
        setStateLocal(oldState => {
            return {
                ...oldState,
                sending: true,
                status: 0,
                help: null,
                sendCounter: oldState.sendCounter + 1
            }
        });
        actionFinished(false);
        actionHideAvatar(false);
    }

    const actionShowNext = () => {
        setShowInfoNextStep(true);
    }

    return (
        <div className={style.root}>

            {(stateLocal.sending) ?
                <div className={style.loading}>
                    <Loader />
                    <div className={style.loadingText}>{t('createClaim.finishSending')} ...</div>
                </div> :
                <>
                    {stateLocal.status === 1 ?
                        <div className={style.errorLayer}>
                            <div className={style.branchContact}>
                                <div className={style.lotties}><Lottie options={{ loop: true, animationData: technicalAnimation }} /></div>
                                <div className={style.header}>{t('createClaim.finishError')}</div>

                                {errorCode &&
                                    <div className={style.errorCode}>{parse(t('createClaim.errorCode', { errorCode: `<strong>#${errorCode}</strong>` }))}</div>
                                }

                                {stateLocal.help &&
                                    <div className={style.dataContact}>
                                        {(stateLocal.help.fullName && stateLocal.help.address) &&
                                            <div className={style.contact}>
                                                <div className={style.ico}><IconHome /></div>
                                                <div className={style.content}>
                                                    {stateLocal.help.fullName}<br />
                                                    {stateLocal.help.address}
                                                </div>
                                            </div>
                                        }
                                        {stateLocal.help.phoneNumber &&
                                            <a className={style.contact} href={`tel:${stateLocal.help.phoneNumber.replaceAll(' ', '')}`}>
                                                <div className={style.ico}><IconPhone /></div>
                                                <div className={style.content}>{stateLocal.help.phoneNumber}</div>
                                            </a>
                                        }
                                        {stateLocal.help.url &&
                                            <a className={style.contact} href={stateLocal.help.url} target="_blank" rel="noreferrer">
                                                <div className={style.ico}><IconWeb /></div>
                                                <div className={style.content}>{stateLocal.help.url}</div>
                                            </a>
                                        }
                                        {validField('email', stateLocal.help.email) &&
                                            <a className={style.contact} href={`mailto:${stateLocal.help.email}`}>
                                                <div className={style.ico}><IconEmail /></div>
                                                <div className={style.content}>{stateLocal.help.email}</div>
                                            </a>
                                        }
                                    </div>
                                }
                                <div className={style.buttonSendGain}>
                                    <Button
                                        onClick={actionSendAgain}
                                        color={'secondary'}
                                        timer={timerCounterTrySend}
                                        onTickInfo
                                        onTimerEnd={() => setTimerCounterTrySend(intervalSecondsTrySend)}
                                    >
                                        {t('createClaim.trySendAgain')}
                                    </Button>
                                </div>
                            </div>
                        </div> :
                        <div className={style.body}>
                            <div className={style.succesLayer}>
                                <div className={style.header}>
                                    {t('createClaim.finishSendLabel1')}
                                    <span>{t('createClaim.finishSendLabel2')}</span>
                                </div>
                                <div className={style.successDescription}>
                                    {(state.tagNumbersAccepted.length > 0 && tagNumberPrepare) &&
                                        <div className={style.successDescriptionTagSeries}>
                                            {parse(t('createClaim.finishDescriptionTagNumber', { tagNumber: `<strong>${tagNumberPrepare}</strong>` }))}
                                        </div>
                                    }
                                    {parse(t('createClaim.finishDescription', { email: `<strong>${user?.email || '_'}</strong>` }))}
                                </div>
                                <div className={style.case}>{t('createClaim.finishCaseNr')}: <span>{stateLocal.caseData?.caseNumber || '-'}</span></div>
                                {urlKey &&
                                    <button className={cx(style.buttonWhite, style.buttonDownload)} onClick={actionDownloadPdf}>
                                        <div className={style.download}>
                                            <OkIcon />
                                        </div>
                                        <span>
                                            {t('createClaim.finishDownloadPdf')}
                                        </span>
                                    </button>
                                }
                            </div>
                            {statusNextAction !== 'finish' &&
                                <div className={style.bottomButton}>
                                    <button className={cx(style.buttonWhite, style.buttonClear)} onClick={statusNextAction === 'single' ? actionNewClaim : actionShowNext}>
                                        {t(statusNextAction === 'single' ? 'createClaim.finishAddAnother' : 'createClaim.finishAddNext')}
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </>
            }
            <ModalNextTag
                actionClose={() => setShowInfoNextStep(false)}
                show={showInfoNextStep && statusNextAction === 'next'}
                t={t}
                tagNumbersAccepted={state.tagNumbersAccepted}
                actionNextTags={actionNextTags}
                actionNewClaim={actionNewClaim}
            />

        </div>
    )
}

export default React.memo(Finish);