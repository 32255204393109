import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import cx from 'classnames';

import ImgLoading from 'Assets/loading.gif';

import ModalRetryPayment from "PageComponents/Chat/CustomChatBlocks/CaseResolutionBlock/ModalRetryPayment/ModalRetryPayment";
import { ClaimT, OrderProductT, OrderStatusE, OrderT } from "api/modules/claim";
import ChatMessageGroup from "Components/ChatMessageGroup/ChatMessageGroup";
import runApi, { ApiSendMethods, ResultApiTypeT } from "api/base/runApi";
import type { StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from "Helpers/responsiveContainers";
import { openClaimChat } from "Store/modules/claimChat/data";
import { ClaimHistoryT } from "api/modules/case-resolution";
import { PaymentsT } from "Store/modules/luggageStore/shop";
import useFormattedPrice from "Hooks/useFormattedPrice";
import { PaymentStatusesE } from "Store/Enums/Payment";
import { runNotify } from "Helpers/notifications";
import { PageConfigT } from "Store/modules/page";
import { PaymentsE } from "Store/Types/order";
import { AppStateT } from "Store/modules";
import styles from './Order.module.scss';
import useOrder from "Hooks/useOrder";

type MainPropsT = {
    claim: ClaimT,
    statusInfo: ClaimHistoryT;
}

const Order: React.FC<MainPropsT> = ({ claim, statusInfo }) => {

    const { t } = useTranslation();
    const { data: user } = useSelector<AppStateT, UserStateT>((state) => state.user);
    const order = useOrder(claim?.orders);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(claim.currencyIsoCode, pageConfig.noFractionCurrencies);
    const claimsArchived = useSelector<AppStateT, boolean>((state) => state.page.claimsArchived);
    const [checkingPayment, setCheckingPayment] = useState(false);
    const [showModalPaymentRetry, setShowModalPaymentRetry] = useState(false);
    const dispatch = useDispatch();
    const [paymentData, setPaymentData] = useState<PaymentsT>(
        {
            creditCard: {
                cardNumber: '',
                cvv: '',
                exp: ''
            },
            paypal: {
                id: null
            },
            wireTransfer: null
        }
    );
    const statusInHeader = useMemo(() => !!order.products.some(p => !!p.status) || order.products.filter(p => p.status !== order.status).length === 0 || order.products.length < 2, [order]);

    const { isMobile } = useDeviceType();

    const getStatusTitle = useMemo(() => (status: string): string => {

        switch (status) {
            case OrderStatusE.DRAFT: return t('order.titleDraft');
            case OrderStatusE.ACTIVATED: return t('order.titleActive');
            case OrderStatusE.ORDERED_DROPSHIPPING: case OrderStatusE.FULFILLED: return t('order.titleSend');
            case OrderStatusE.DEACTIVATED: return t('order.titleCanceled');
            case OrderStatusE.COMPLETED: return t('order.titleCopleted');
            case OrderStatusE.ORDER_IN_PROCESS: return t('order.titleProcessing');
            default: return t('order.titleProcessing');
        }

    }, [t]);

    const getImageUrl = useMemo(() => (product: OrderProductT) => {
        if (product.photoUrl) return product.photoUrl;
        return '';
    }, []);

    const hasDeliveryDaysContent = useMemo(() => (daysAmount: string): boolean => {
        const keyTranslate = 'shop.deliveryDays'
        if (t(keyTranslate, { deliveryDays: daysAmount }) !== keyTranslate) {
            return true
        }
        return false
    }, [t]);

    const getDeliveryName = useMemo(() => (name: string): string => {
        const translateName = t(`shop.${name}`);
        if (translateName !== name) return translateName;
        return name;
    }, [t]);

    const deliveryDaysContent = useMemo(() => (daysAmount: string): string | null => {
        const keyTranslate = 'shop.deliveryDays'
        if (t(keyTranslate, { deliveryDays: daysAmount }) !== keyTranslate) {
            return t(keyTranslate, { deliveryDays: daysAmount })
        }
        return null
    }, [t]);

    const statusPaymentText = useMemo(() => (status: OrderT['payment']['status']): string => {
        if (status === PaymentStatusesE.COMPLETED) {
            return t('order.payStatusSuccess');
        }
        if (status === PaymentStatusesE.FAILED) {
            return t('order.payStatusFailed');
        }
        return t('order.payStatusProcessing');
    }, [t]);

    const getPaymentName = useMemo(() => (type: string) => {
        switch (type.toUpperCase()) {
            case PaymentsE.WIRE_TRANSFER: return t('shop.bankWire');
            case PaymentsE.CREDIT_CARD: return t('shop.creditCard');
            case PaymentsE.PAYPAL: return t('shop.paypal');
            case PaymentsE.CASH_ON_DELIVERY: return t('shop.cashOnDelivery');
            default: return type;
        }
    }, [t]);

    const actionRetryPayment = useCallback((dataPayment?: PaymentsT['creditCard']) => {
        if (!order?.id) return;
        setCheckingPayment(true);

        const dataSend: any = {
            orderId: order.id,
            selectedType: order.payment?.selectedType
        }

        if (dataPayment && order?.payment?.selectedType === PaymentsE.CREDIT_CARD) {
            const creditCardDates = dataPayment.exp.split('/');

            dataSend.creditCard = {
                cvc2: dataPayment.cvv,
                expireMonth: isNaN(+creditCardDates[0]) ? 0 : +creditCardDates[0],
                expireYear: +(creditCardDates?.[1] || 0),
                firstname: order?.shipment?.firstName || '',
                lastname: order?.shipment?.lastName || '',
                pan: +dataPayment.cardNumber.replaceAll("-", ""),
            }

        }

        runApi(`claim/${claim.id}/shop/payment/retry`, dataSend, (r: ResultApiTypeT) => {
            setCheckingPayment(false);
            if (r.result) {

                const status: OrderT['payment']['status'] = r.data.status;
                if (status === PaymentStatusesE.FAILED) {
                    runNotify({ message: t('order.payStatusFailed'), type: 'error' });
                } else if (status === PaymentStatusesE.COMPLETED) {
                    // płatnoś zakończona, odświerzy dane
                    runNotify({ message: t('order.payStatusSuccess'), type: 'success' });
                    dispatch(openClaimChat(claim.id, claimsArchived, user.id));
                } else if (status === PaymentStatusesE.REDIRECT && r.data?.redirectUrl) {
                    window.location.href = r.data.redirectUrl;
                } else {
                    runNotify({ message: t('order.payStatusProcessing'), type: 'info' });
                }

            } else {
                // comment error
                runNotify({ message: r.error.message || t('common.formSendError'), type: 'error' });
            }
        }, ApiSendMethods.post);
    }, [claim.id, t, order?.id, dispatch, order?.payment?.selectedType, order?.shipment?.firstName, order?.shipment?.lastName, claimsArchived, user.id]);

    const actionCheckPayment = useCallback(() => {
        if (!order?.id) return;

        if (order?.payment?.selectedType === PaymentsE.CREDIT_CARD) {
            return setShowModalPaymentRetry(true);
        }
        actionRetryPayment();

    }, [order?.id, order?.payment?.selectedType, actionRetryPayment]);

    return (
        <>
            <ChatMessageGroup
                direction="incoming"
                data={[
                    {
                        render: () => (
                            <div className={cx(styles.order, { [styles.canceled]: order.status === 'DEACTIVATED', [styles.completed]: order.status === 'COMPLETED', [styles.mobile]: isMobile })}>
                                {order.orderNumber &&
                                    <div className={styles.orderNumber}>
                                        <div className={styles.orderData}>
                                            {t('order.orderNumber')}: <strong>{order.orderNumber}</strong>
                                        </div>
                                    </div>
                                }
                                {statusInHeader &&
                                    <div className={styles.header}>
                                        {getStatusTitle(order.status)}
                                    </div>
                                }
                                <div className={styles.products}>
                                    {order.products.map((product: OrderProductT) => (
                                        <div
                                            className={cx(styles.product, { [styles.deactivated]: product.status === OrderStatusE.DEACTIVATED })}
                                            key={`productOrder_${product.name}${product.size}${product.color}`}
                                        >
                                            <div className={styles.image}><img src={getImageUrl(product)} alt="" /></div>
                                            <div className={styles.content}>
                                                <div className={styles.title}>{product.name}</div>
                                                {(product.deliveryDays && hasDeliveryDaysContent(product.deliveryDays) && product.status !== OrderStatusE.DEACTIVATED) &&
                                                    <div className={styles.deliveryDays}>{parse(deliveryDaysContent(product.deliveryDays))}</div>
                                                }
                                                <div className={styles.price}><span className='price'>{formatPrice(product.amount)}{' '}{order.shipment.deliveryMethod.currency.code}</span></div>
                                                {!statusInHeader &&
                                                    <div className={styles.status}>
                                                        {getStatusTitle(product.status)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.subheader}>{t('shop.formDeliveryMethod')}:</div>
                                <div className={styles.value}>{getDeliveryName(order.shipment.deliveryMethod.name)}</div>
                                <div className={styles.subheader}>{t('shop.paymentMethod')}:</div>
                                <div className={styles.value}>{order.payment?.selectedType ? getPaymentName(order.payment.selectedType) : '-'}</div>
                                {(order?.payment && order.payment.selectedType !== 'CASH_ON_DELIVERY') &&
                                    <>
                                        <div className={styles.subheader}>{t('order.payStatusTitle')}:</div>
                                        <div
                                            className={cx(styles.value, {
                                                [styles.error]: order.payment.status === PaymentStatusesE.FAILED,
                                                [styles.success]: order.payment.status === PaymentStatusesE.COMPLETED,
                                                [styles.warning]: order.payment.status === PaymentStatusesE.PROCESSING || order.payment.status === PaymentStatusesE.REDIRECT,
                                            }
                                            )}
                                        >
                                            {statusPaymentText(order.payment.status)}
                                        </div>
                                        {(order.status === 'DRAFT' && ((order.payment.status === 'REDIRECT' && order.payment.redirectUrl) || order.payment.status === 'FAILED')) &&
                                            <div className={cx(styles.redirectButton, { [styles.disabled]: checkingPayment })} onClick={() => {
                                                if (checkingPayment) return;
                                                actionCheckPayment();
                                            }}>
                                                <div className={styles.redirectBody}>
                                                    {checkingPayment &&
                                                        <div className={styles.loading}><img src={ImgLoading} alt="" /></div>
                                                    }
                                                    {t(`order.${order.payment.status === 'REDIRECT' ? 'finishRedirect' : 'finishRedo'}`)}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                <div className={styles.subheader}>{t('shop.formDelivery')}:</div>
                                <div className={styles.value}>{order.shipment.firstName} {order.shipment.lastName}, {order.shipment.location.address}, {order.shipment.location.zipCode} {order.shipment.location.city}, {order.shipment.location.country}</div>
                                <div className={styles.subheader}>{t('shop.summary')}:</div>
                                <div className={styles.value}>{t('shop.voucherTitle')}: <strong>{formatPrice(order.amountVoucher)}{' '}{order.shipment.deliveryMethod.currency.code}</strong></div>
                                {/* <div className={styles.value}>{t('order.deliveryCost')}: <strong>{order.shipment.deliveryMethod.value.toFixed(2)}</strong>{order.shipment.deliveryMethod.currency.code}</div> */}
                                <div className={styles.value}>{t('order.totalPay')}: <span className={styles.total}><strong>{formatPrice(order.amountTotal)}{' '}{order.shipment.deliveryMethod.currency.code}</strong></span></div>
                            </div>
                        ),
                        className: styles.messageIn,
                    },
                ]}
                statusInfo={statusInfo}
                avatar="bot"
            />
            {showModalPaymentRetry &&
                <ModalRetryPayment
                    order={order}
                    actionClose={() => setShowModalPaymentRetry(false)}
                    actionSubmit={(data: PaymentsT['creditCard']) => {
                        setShowModalPaymentRetry(false);
                        actionRetryPayment(data);
                    }}
                    paymentData={paymentData}
                    setPaymentData={setPaymentData}
                />
            }
        </>
    );
}
export default Order;