import React, { useCallback, useEffect, useState } from 'react';
// import cx from 'classnames';

import CreditCard from "PageComponents/LuggageStore/ShoppingCartMainScreen/Payments/CreditCard/CreditCard";
import { useDeviceType } from "Helpers/responsiveContainers";
import { PaymentsT } from "Store/modules/luggageStore/shop";
import style from './ModalRetryPayment.module.scss';
import { validField } from "Helpers/validForm";
import useTranslate from "Hooks/useTranslate";
import { OrderT } from "api/modules/claim";
import { Button} from "Components";

type MainPropsT = {
    order: OrderT,
    actionSubmit: (data: PaymentsT['creditCard']) => void,
    actionClose: () => void,
    paymentData: PaymentsT,
    setPaymentData: (data: any) => void
}

const ModalRetryPayment: React.FC<MainPropsT> = ({ order, actionClose, actionSubmit, paymentData, setPaymentData }) => {

    const t = useTranslate();
    const [valid, setValid] = useState(false);
    const { isDesktop } = useDeviceType();

    useEffect(() => {
        setValid(validField('creditCard-cardNumber', paymentData.creditCard.cardNumber) && validField('creditCard-exp', paymentData.creditCard.exp) && validField('creditCard-cvv', paymentData.creditCard.cvv));
    }, [paymentData.creditCard]);

    const actionSend = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        actionSubmit(paymentData.creditCard);
    }, [actionSubmit, paymentData]);

    const actionChangeDataPayment = useCallback((type: string, data: string) => {
        setPaymentData(old => {
            return {
                ...old,
                creditCard: {
                    ...old.creditCard,
                    [type]: data
                }
            }
        });
    }, [setPaymentData]);

    return (
        // <Modal
        //     open
        //     onClose={actionClose}
        //     title={t('order.paymentRetry')}
        //     animatedContentClassName={cx(style.modalContent, { [style.desktop]: isDesktop })}
        // >
            <form onSubmit={actionSend} className={style.form}>
                <div className={style.root}>
                    <div className={style.body}>
                        <p>
                            {t('order.paymentRetryCardTitle')}
                        </p>
                        <CreditCard 
                            actionChange={actionChangeDataPayment}
                            payments={paymentData}
                            validField={validField}
                            mobile={!isDesktop}
                            t={t}
                            currency={order.currency}
                        />
                    </div>
                    <div className={style.buttons}>
                        <Button onClick={actionClose} color={'secondary'}>Anuluj</Button>
                        <Button type={'submit'} disabled={!valid}>Wyślij</Button>
                    </div>
                </div>
            </form>
    //   </Modal>
    );
}
export default ModalRetryPayment;