import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import cx from 'classnames';

import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import { ClaimT, IClaimFeed } from "./DataStructure/interfaces";
import { useDeviceType } from 'Helpers/responsiveContainers';
import { ClaimChatStateT } from "Store/modules/claimChat";
import { ClaimProgressStageE } from "Store/Enums/claim";
import type { AppStateT } from 'Store/modules';
import { getClaimFeed } from "services/api";
import { Chat } from "PageComponents/Chat";
import styles from './Feed.module.scss';
import { Loader } from 'Components';
import Entry from "./Entry/Entry";

export type nun = null | undefined;
export interface IPossibleResolutions {
  repair: boolean;
  refund: number | false;
  voucher: number | false;
}

export enum RESOLUTION_TYPE {
	NONE = '',
	CASH_REFUND = 'cashRefund',
	REPAIR = 'repair',
	VOUCHER = 'VOUCHER',
	REPAIR_DROP_OFF = 'dropOff',
	PICK_UP = 'pickUp',

	// Cash refund subtypes
	PAYPAL = 'paypal',
	BANK = 'wireTransfer',
}

type ChatT = {
	onChooseResolution?: (resolutionType: RESOLUTION_TYPE) => void;
	claimId: string;
	openFromMobile?: boolean,
	claim?: ClaimT | undefined,
	actionParam?: string,
	openStore: () => void
}

const idNameFeedLayer = 'feedIdFeed';

const Feed: React.FC<ChatT> = ({ openStore, claimId, onChooseResolution = () => { }, openFromMobile, claim: claimProps, actionParam }) => {
	const { claim } = useSelector<AppStateT, Pick<ClaimChatStateT['data'], 'claim'>>(({ claimChat }) => ({ claim: claimChat.data.claim }));
	const { feedUpdateCounter } = useSelector<AppStateT, ChatDataStateT>(data => data.claimChat.data);
	const { isNotMobile, isMobile } = useDeviceType();
	const [feed, setFeed] = useState<IClaimFeed|null>(null);
	const [loaded, setLoaded] = useState(false);
	const { t } = useTranslation();
	const bottomRef = useRef(null);

	useEffect(() => {
		if (claim?.feed && claim.feed.entries.some(e => e.stage === ClaimProgressStageE.REGISTERED)){
			setLoaded(true);
			setFeed(claim?.feed as IClaimFeed);
		} else {
			setLoaded(false);
			if (claim?.id){
				getClaimFeed(claim.id, (success, data) => {
					setLoaded(true);
					if (success && data && Array.isArray(data?.entries) && data.entries.some(e => e.stage === ClaimProgressStageE.REGISTERED)){
						setFeed(data);
					}
				});
			}
		}
	}, [claim?.feed, claim?.id]);

	useLayoutEffect(() => {
		if (feed){
			const feedObject = document.getElementById(idNameFeedLayer);
			if (feedObject){
				setTimeout(() => {
					if (isMobile){
						bottomRef.current?.scrollIntoView?.({ behavior: 'smooth' });
					} else {
						feedObject.scrollTo({
							top: feedObject.scrollHeight,
							behavior: 'smooth'
						});
					}
				}, 300);
			}
		}
	}, [feed, feedUpdateCounter, isMobile]);

	useEffect(() => {
		if (claim?.id && feed?.id && (feed?.unreadEntries || 0) > 0){
			getClaimFeed(claim.id, (result, data) => {
				if (result){
					setFeed(data);
				}
			});
		}
	}, [feed?.id, feed?.unreadEntries, feedUpdateCounter, claim?.id]);

	if (!claim || !loaded) {
		return (
			<div className={styles.loaderWrapper}>
				<Loader />
			</div>
		);
	}

	if (!feed){
		return (
			<Chat
				claimId={claimId}
				onChooseResolution={onChooseResolution}
				actionParam={actionParam}
				openStore={openStore}
				claim={claim}
			/>
		)
	}

	return (
		<>
			<div className={cx(styles.root, { [styles.rootDesktop]: isNotMobile })} id={idNameFeedLayer}>

				{feed.entries.filter(entry => !entry.isHidden).map(entry => {
					return (
						<Entry
							entry={entry}
							key={`entry-${entry.id}`}
							t={t}
							claim={claim}
							isMobile={isMobile}
						/>
					)
				})}

			</div>
			<div ref={bottomRef} />
		</>
	);
}

export default React.memo(Feed);