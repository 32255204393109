import { useState, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button} from 'Components';
import * as Sentry from "@sentry/react";
import parse from 'html-react-parser';

import { CartProductT, CartT, changeShopCounter, clearCart, clearPayment, DeliveryOrderT, setAgreeTerms, setNote, setValidForm, ShopStateT, showModalOrderSuccess } from 'Store/modules/luggageStore/shop';
import { ShoppingCartList, ShoppingCartTotal, StoreCartMobileDeliveryForm } from 'PageComponents/LuggageStore';
import ShoppingCartVoucher from '../ShoppingCartMenu/ShoppingCartVoucher/ShoppingCartVoucher';
import { changeModalArticle, setReloadClaims, StoreConfigT } from 'Store/modules/page';
import ModalSuccess from 'Pages/StoreDesktop/ModalSuccess/ModalSuccess';
// import ModalRedirect from "Components/ModalRedirect/ModalRedirect";
import formatDeliveryAddress from 'Helpers/formatDeliveryAddress';
import PaymentsMethods from './PaymentsMethods/PaymentsMethods';
import { LuggageStoreStateT } from "Store/modules/luggageStore";
import { checkValidForm, validField } from 'Helpers/validForm';
// import { DeliveryOptions } from 'PageComponents/LuggageStore';
import styles from './ShoppingCartMobileCheckout.module.scss';
import { PaymentsE} from "Store/Types/order";
import { StateT as UserStateT } from 'Store/modules/user';
import { PaymentStatusesE } from 'Store/Enums/Payment';
import { reload } from 'Store/modules/claimChat/data';
import Checkbox from 'Components/Checkbox/Checkbox';
import Textarea from 'Components/Textarea/Textarea';
import { getDataOrder } from 'Helpers/useDataCart';
import { runNotify } from 'Helpers/notifications';
import Loading from 'Components/Loading/Loading';
import { ErrorsEnumE } from "Store/Enums/errors";
import { OptionItem } from 'PageComponents/Chat';
import type { AppStateT } from 'Store/modules';
import { calculateCart } from 'Helpers/store';
import runApi, { ResultApiTypeT } from 'api/base/runApi';

interface IShoppingCartMobileCheckout {
    cart: CartT;
    voucherValue: number;
    removeFromCart: (data: CartProductT) => void;
    closeCart: () => void,
    onItemSelect: (id: number) => void,
    claimId: string,
    closeShop?: () => void
}

export default function ShoppingCartMobileCheckout(props: IShoppingCartMobileCheckout) {
    const { termsAccept, voucher, delivery, configShop, rabatCode, cart, validForm, claim, showModalSuccess, note } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const storeConfig = useSelector<AppStateT, StoreConfigT>((state) => state.page.storeConfig);
    const { data: userData } = useSelector<AppStateT, UserStateT>(({ user }) => user);
    // const [deliveryOptionsIsOpen, setDeliveryOptionsIsOpen] = useState(false);
    const [deliverFormIsOpen, setDeliverFormIsOpen] = useState(false);
    const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
    const [validDelivery, setValidDelivery] = useState(false);
    const [validPayment, setValidPayment] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [sendingOrder, setSendingOrder] = useState(false);
    const [noteValueOnFocus, setNoteValueOnFocus] = useState('');
    const [showNote, setShowNote] = useState(!!note);
    const [firstShowCounter, setFirstShowCounter] = useState(false);
    // const [redirect, setRedirect] = useState<RedirectDataT>({ type: null, url: null });
    // const [content, setContent] = useState('');
    const { catalog } = useSelector<AppStateT, Pick<LuggageStoreStateT, 'catalog'>>(({ store }) => ({ catalog: store.catalog }));

    useEffect(() => {
        const translateKey = `shop.${delivery.deliveryMethod.name}_DESCRIPTION`;
        const text = t(translateKey);
        if (text !== translateKey){
            // return setContent(text);
        }
        // setContent('');
    }, [t, delivery.deliveryMethod.name]);

    useLayoutEffect(() => {
        const elementTerms = document.getElementById('termsLabelCheckbox');
        if (elementTerms){
            const actionEvent = (event) => {
                event.stopPropagation();
                dispatch(changeModalArticle({ pagesContentKey: 'terms' }));
            }

            elementTerms.addEventListener('click', actionEvent);

            return () => {
                elementTerms.removeEventListener('click', actionEvent);
            }
        }
    }, [dispatch]);

    useEffect(() => {
        checkValidForm(dispatch, cart, termsAccept, setValidForm, delivery, totalAmount);
    }, [termsAccept, delivery, cart, dispatch, totalAmount]);

    useEffect(() => {
        if (totalAmount <=0 ){
            dispatch(clearPayment());
        }
    }, [dispatch, totalAmount]);

    useEffect(() => {
        if (note && !firstShowCounter){
            setShowNote(true);
            setFirstShowCounter(true);
        }
    }, [note, firstShowCounter]);

    useEffect(() => {
        const amountsData = calculateCart(cart, voucher, rabatCode, 0, configShop?.paymentSkipThreshold?.amount);
        const totalValue = amountsData.isTotalSpecial ? 0 : amountsData.total;
        setTotalAmount(totalValue);
    }, [cart, voucher, rabatCode, configShop?.paymentSkipThreshold?.amount]);

    const actionValidAdress = useCallback(() => {
        const adressFieleds = ['name', 'surname', 'street', 'city', 'zipCode'];
        for(let i=0;i<adressFieleds.length;i++){
            if (!validField(`clientAddress-${adressFieleds[i]}`, delivery.clientAddress[adressFieleds[i]])){
                return setValidDelivery(false);
            }
        }
        if (!delivery.clientAddressDeliveryOn){
            for(let i=0;i<adressFieleds.length;i++){
                if (!validField(`clientAddressDelivery-${adressFieleds[i]}`, delivery.clientAddressDelivery[adressFieleds[i]])){
                    return setValidDelivery(false);
                }
            }
        }
        setValidDelivery(true);
    }, [delivery]);

    const actionValidPayment = useCallback(() => {
        if (!delivery.paymentChoose){
            return setValidPayment(false);
        }

        if (delivery.paymentChoose === 'WIRE_TRANSFER'){
            return setValidPayment(true);
        }

        if (delivery.paymentChoose === 'PAYPAL'){
            // if (!validField('paypal-id', delivery.payment.paypal.id)){
            //     return setValidPayment(false);
            // }
        }

        if (delivery.paymentChoose === 'CREDIT_CARD'){
            const fields = ['cardNumber', 'exp', 'cvv'];
            for(let i=0;i<fields.length;i++){
                if (!validField(`creditCard-${fields[i]}`, delivery.payment.creditCard[fields[i]])){
                    return setValidPayment(false);
                }
            }
        }
        setValidPayment(true);
    }, [delivery]);

    useEffect(() => {
        actionValidAdress();
        actionValidPayment();
    }, [delivery, actionValidAdress, actionValidPayment]);

    const handleContinueShopping = () => {
        props.closeCart();
    }

    const actionShowProduct = useCallback((cartProduct: CartProductT) => {
        props.onItemSelect(cartProduct.productSimpleId);
    }, [props]);

    const actionSetReg = useCallback(() => {
        dispatch(setAgreeTerms(!termsAccept));
        dispatch(changeShopCounter());
    }, [dispatch, termsAccept]);

    // const actionDeliveryOption = useCallback((deliveryMethod: DeliveryMethodT) => {
    //     dispatch(setDelivery({
    //         ...delivery,
    //         deliveryMethod
    //     }));
    //     dispatch(changeShopCounter());
    // }, [dispatch, delivery]);

    // const actionReceiveMessage = useCallback((deliverySms: boolean) => {
    //     dispatch(setDelivery({
    //         ...delivery,
    //         deliverySms
    //     }));
    //     dispatch(changeShopCounter());
    // }, [dispatch, delivery]);

    const choosePaymentTitle = useCallback((): string => {
        if (delivery.paymentChoose){
            if (delivery.paymentChoose === PaymentsE.PAYPAL) return t('shop.paypal');
            if (delivery.paymentChoose === PaymentsE.CREDIT_CARD) return t('shop.creditCard');
            if (delivery.paymentChoose === PaymentsE.CASH_ON_DELIVERY) return t('shop.cashOnDelivery');
            return t('shop.bankWire');
        }
        return '';
    }, [delivery, t]);

    // const getMessageError = (data: string): string|null => {

    //     if (!data) return t('shop.errorPayment');
    //     if (userData.language === 'pl'){
    //         return t('shop.errorPayment');
    //     }
    //     return data;
    // }

    const submitAction = () => {
        if (!validForm || !claim) return;

        const correctDelivery: DeliveryOrderT = {
            ...delivery,
            deliveryMethod: {
                ...delivery.deliveryMethod,
                currency: {
                    code: storeConfig.currency.code,
                    symbol: storeConfig.currency.symbol,
                }
            },
            clientAddress: {
                ...delivery.clientAddress,
                country: delivery.clientAddress.country || (claim?.location.country || '')
            },
            clientAddressDelivery: {
                ...delivery.clientAddressDelivery,
                country: delivery.clientAddressDelivery.country || (claim?.location.country || '')
            },
        };

        setSendingOrder(true);
        const dataSend = getDataOrder(rabatCode, voucher, cart, correctDelivery, userData, storeConfig, note, catalog.data);
        runApi(`claim/${claim.id}/shop/order`, dataSend, (r: ResultApiTypeT) => {
            if (r.result){
                if (!dataSend.payment || r.data?.payment?.status === PaymentStatusesE.COMPLETED || (r.data?.payment?.status === PaymentStatusesE.PROCESSING && r.data.payment.selectedType === PaymentsE.WIRE_TRANSFER && r.data.status === 'DRAFT')){
                    dispatch(clearCart(true));
                    dispatch(showModalOrderSuccess(true));
                } else if (r.data?.payment?.status === PaymentStatusesE.FAILED){
                    runNotify({ message: r.data.payment?.internalError?.message || 'Error while payment...', type: 'error' });
                    Sentry.withScope(scope => {
                        scope.setTag("erro_message", r.data.payment?.internalError?.message || 'Error while payment...');
                        scope.setExtra("title", r.data?.payment?.internalError?.name);
                        Sentry.captureMessage( 'Order Payment Error', "error");
                    });
                } else if (r.data?.payment?.status === PaymentStatusesE.REDIRECT){
                    // setRedirect({
                    //     type: dataSend.payment?.paypal ? 'paypal' : 'card',
                    //     url: r.data.payment.redirectUrl
                    // });
                } else {
                    runNotify({ message: t('shop.errorPayment') || 'Error while payment...', type: 'error' });
                }
            } else {
                if ((r.error?.code as string) === ErrorsEnumE.ORDER_ALREADY_CREATED){
                    // setRedirect({
                    //     type: 'orderAlready',
                    //     url: `/${claim.id}`,
                    //     buttonLabel: t('shop.orderExistButton'),
                    //     content: contentOrderExist()
                    // });

                } else {
                    let _error = t('shop.errorPayment') || 'Error while payment...';
                    if ((r.error?.code as string) === ErrorsEnumE.CLAIM_IS_BLOCKED){
                        _error = t('caseStatusesHeaders.BLOCKED');
                    }
                    runNotify({ message: _error, type: 'error' });
                }
            }
            setSendingOrder(false);
        });
        
    }

    // const contentOrderExist = useMemo(() => (): JSX.Element => {
    //     return (
    //         <div>
    //             <p>{t('shop.dearClient')}, </p>
    //             <p>{parse(t('shop.orderExistContent', { caseNumber: `<strong>${claim?.caseNumber || ''}</strong>` }))}</p>
    //         </div>
    //     )
    // }, [claim?.caseNumber, t]);

    const actionGoClaim = () => {
        dispatch(reload());
        dispatch(setReloadClaims());
        props.closeShop?.();
    }

    const getDeliveryName = useMemo(() => (name: string): string => {
        const translateName = t(`shop.${name}`);
        if (translateName !== name) return translateName;
        return name;
    }, [t]);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <ShoppingCartList
                    cart={props.cart}
                    className={styles.cartList}
                    removeFromCart={props.removeFromCart}
                    actionShowProduct={actionShowProduct}
                />

                <div>
                    <OptionItem
                        data={{
                            title: t('shop.delivery'),
                            description: formatDeliveryAddress(delivery),
                            chevron: true,
                        }}
                        fixedBorderRadius
                        onClick={() => setDeliverFormIsOpen(true)}
                        bodyClassName={styles.option}
                        titleClassName={styles.optionItemTitle}
                        descriptionClassName={styles.optionItemDescription}
                        valid={validDelivery}
                    />
                    <OptionItem
                        data={{
                            title: t('shop.deliveryOptions'),
                            description: `${getDeliveryName(delivery.deliveryMethod.name)} - ${delivery.deliveryMethod.value} ${delivery.deliveryMethod.currency.code}`,
                            chevron: true,
                        }}
                        // onClick={() => setDeliveryOptionsIsOpen(true)}
                        fixedBorderRadius
                        bodyClassName={styles.option}
                        titleClassName={styles.optionItemTitle}
                        descriptionClassName={styles.optionItemDescription}
                    />
                    {((totalAmount > 0 && cart.products.length > 0) || (totalAmount === 0 && cart.products.length === 0)) &&
                        <OptionItem
                            data={{ 
                                title: t('shop.paymentMethod'), 
                                chevron: true,
                                description: choosePaymentTitle()
                            }}
                            fixedBorderRadius
                            bodyClassName={styles.option}
                            titleClassName={styles.optionItemTitle}
                            descriptionClassName={styles.optionItemDescription}
                            onClick={() => setPaymentMethodsOpen(true)}
                            valid={validPayment}
                        />
                    }
                </div>

                <ShoppingCartVoucher isMobile claimId={props.claimId} />

                <div className={styles.notes}>
                    {!showNote ?
                        <div onClick={() => setShowNote(true)} className={styles.titleShow}>{t('shop.formDeliveryOptions')}</div>:
                        <div className={styles.notesArea}>
                            <Textarea
                                label={t('shop.formDeliveryOptions')}
                                onChangeText={(value) => dispatch(setNote(value))}
                                value={note}
                                className={styles.textarea}
                                maxLength={50}
                                onBlur={() => {
                                    if (noteValueOnFocus !== note){
                                        dispatch(changeShopCounter());
                                    }
                                }}
                                onFocus={() => setNoteValueOnFocus(note)}
                            />
                        </div>
                    }
                </div>

                <ShoppingCartTotal
                    cart={props.cart}
                    deliveryPrice={delivery.deliveryMethod?.value ? delivery.deliveryMethod.value : 0}
                    creditCardServicePrice={5}
                    voucherValue={voucher}
                    className={styles.checkoutContainer}
                    currencySymbol={configShop.currency.symbol}
                    currencyCode={configShop.currency.code}
                    rabat={rabatCode}
                    t={t}
                />

                <div className={styles.regLayer} onClick={() => actionSetReg()}>
                    <Checkbox checked={termsAccept} name={'Reg'} />
                    <span>
                        {parse(t('shop.formGeneral', { terms: `<label class="terms" id="termsLabelCheckbox">${t('footer.termsAndConditions')}</label>` }))}
                    </span>
                </div>

                <Button className={styles.button} disabled={!validForm} onClick={submitAction}>
                    {t('shop.placeOrder')}
                </Button>

                <Button className={styles.button} color="secondary" onClick={handleContinueShopping}>
                    {t('shop.continueButton')}
                </Button>

                <StoreCartMobileDeliveryForm
                    open={deliverFormIsOpen}
                    onClose={() => setDeliverFormIsOpen(false)}
                />

                {/* <Modal open={deliveryOptionsIsOpen} onClose={() => setDeliveryOptionsIsOpen(false)} title={t('shop.deliveryOptions')} back={() => setDeliveryOptionsIsOpen(false)}>
                    <DeliveryOptions
                        onValueChange={actionDeliveryOption}
                        receiveSmsMassage={delivery.deliverySms}
                        onReceiveSmsMassageChange={actionReceiveMessage}
                        value={delivery.deliveryMethod}
                    />

                    {content && 
                        <div className={styles.deliveryContent}>{content}</div>
                    }

                    <div className={styles.buttonContainer}>
                        <Button type="submit" className={styles.btn} onClick={() => setDeliveryOptionsIsOpen(false)}>
                            {t('common.close')}
                        </Button>
                    </div>
                </Modal> */}

                <PaymentsMethods
                    actionClose={() => setPaymentMethodsOpen(false)}
                    show={paymentMethodsOpen}
                    amountTotal={totalAmount}
                />
                {sendingOrder &&
                    <Loading backdrop subtitle={t('shop.sendingOrder')} fixed />
                }
            </div>
            {showModalSuccess &&
                <ModalSuccess 
                    actionClose={actionGoClaim}
                    open t={t}
                    actionGoClaim={actionGoClaim}
                />
            }
            {/* {redirect.url &&
                <ModalRedirect
                    open
                    content={redirect.content || t('shop.redirectContent')}
                    url={redirect.url}
                    size={redirect.type === 'orderAlready' ? 'big' : 'small'}
                    seconds={redirect.type === 'orderAlready' ? 1160 : 1130}
                    actionAfterRedirect={() => {
                        dispatch(clearCart(true));
                        dispatch(showModalOrderSuccess(true));
                    }}
                    buttonSend={redirect.buttonLabel || t('shop.redirectButtonSend')}
                />
            } */}
        </>
    );
}
