import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import 'react-circular-progressbar/dist/styles.css';
import style from './Header.module.scss';
import { ReactComponent as Avatar } from 'Assets/ChatBotBig_Icon.svg';
import { StepsE } from "Store/Enums/claim";
import { StateLocalT } from '../CreateForm';
import { IconButton } from 'rsuite';
import BackIcon from '@rsuite/icons/ArowBack';
import CloseIcon from '@rsuite/icons/Close';


type MainProps = {
    state: StateLocalT,
    actionBack?: () => void,
    closeAction: () => void,
    mobile: boolean,
    t: (param: string, object?: any) => string,
    readOcr?: boolean
}

const stepsNoAvatar = [StepsE.WARNING_ATTACHMENTS];

const Header: React.FC<MainProps> = ({ state, actionBack, closeAction, mobile, t, readOcr }) => {

    const [canBack, setCanBack] = useState(false);

    useEffect(() => {
        if (!state.step){
            return setCanBack(false);
        }
        const indexActualPrev = state.steps.findIndex(step => step.type === state.step.type);
        let indexGoPrev: number = -1;
        for(let i=state.steps.length;i>=0;i--){
            if (!state.steps[i]?.optional && i < indexActualPrev){
                indexGoPrev = i;
                break;
            }
        }
        setCanBack(indexGoPrev >= 0);
    }, [state.steps, state.step]);

    return (
        <div className={cx(style.header, { [style.headerMobile]: !!mobile, [style.missedDoc]: !!state.step.missedDoc })} id="headerAddClaim">
            <div className={cx(style.stepLabel, { [style.stepShow]: !!state.step?.stepLabel })}>
                {state.step?.stepLabel || ' '}
            </div>
            <div className={cx(style.basicLayer, { [style.layerAvatar]: state.step?.headerType === 'avatar' })}>
                {state.editStepIndex === null &&
                    <>
                        <div className={style.ico}>
                            {(state.step?.type !== 'finish' && canBack) &&
                                <IconButton 
                                    className={style.circleButton}
                                    icon={<BackIcon />}
                                    onClick={actionBack}
                                    appearance={'subtle'}
                                />
                            }
                        </div>
                        <div className={style.rightLabel} onClick={closeAction}>
                            <IconButton
                                className={style.circleButton}
                                icon={<CloseIcon />}
                                onClick={closeAction}
                                appearance={'subtle'}
                            />
                        </div>    
                    </>
                }
            </div>
            {(!stepsNoAvatar.includes(state.step.type) && !readOcr) && 
                <div className={cx(style.avatar, { [style.big]: (state.step?.headerType === 'bigAvatar'), [style.hide]: state.hideHeader })}>
                    <Avatar />
                    <CircularProgressbar
                        value={(state.stepIndex) * 100 / (state.steps.length - 1)}
                        className={style.circlePropgress}
                        strokeWidth={6}
                        background={false}
                        styles={buildStyles({
                            pathColor: '#2FC3DD',
                            trailColor: '#E6E9EA'
                        })}
                    />
                </div>
            }
            {(state.step?.headerTitle && !readOcr) &&
                <div className={style.headerTitle}>{state.step.headerTitle}</div>
            }
            {!readOcr &&
                <div className={cx(style.headerText, { [style.active]: !!state.step?.headerText, [style.topTitle]: !!state.step?.headerTitle, [style.headerTextShort]: state.step?.headerType === 'bigAvatar', [style.headerHide]: state.hideHeader })}>
                    {state.step?.headerText}
                    {(state.step?.headerNotRequired || false) && <div className={style.notRequired}>- {t('createClaim.notRequired')} -</div>}
                </div>
            }
        </div>
    )
}

export default Header;