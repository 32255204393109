import { error, loading } from 'Helpers/store-async';
import {
  CLAIM_REPAIR_DROP_OFF_SUCCESS,
  CLAIM_REPAIR_PICK_UP_SUCCESS,
  ClaimRepairDropOffSuccessT,
  ClaimRepairPickUpSuccessT,
} from './repair';
import { ClaimChatMessagesT, ClaimT, IClaimFeed, VerdictT, WebsocketChatMessageT } from 'api/modules/claim';
import { ClaimHistoryT, ResolutionT } from 'api/modules/case-resolution';
import { CLAIM_STATUSES } from 'Config/statuses';
import {
  WEBSOCKET_ASSESSMENT_TIMEOUT,
  WEBSOCKET_CLAIM_REPORT_RECEIVED,
  WEBSOCKET_CLAIM_REPORT_RESOLVED,
  WEBSOCKET_CLAIM_RESOLUTION_RECEIVED,
  WEBSOCKET_CLAIM_VERDICT_RECEIVED,
  WEBSOCKET_NEW_STATUS_RECEIVED,
  WEBSOCKET_SET_CATALOGS,
  WEBSOCKET_SET_CLAIM,
  WEBSOCKET_SHIPMENT_UPDATE,
  WebsocketAssessmentTimeoutT,
  WebsocketClaimReportReceivedT,
  WebsocketClaimReportResolvedT,
  WebsocketClaimResolutionReceivedT,
  WebsocketClaimVerdictReceivedT,
  WebsocketNewClaimStatusReceivedT,
  WebsocketSetCatalogsT,
  WebsocketSetClaimT,
  WebsocketShipmentUpdateT,
} from 'Store/modules/websocket';
import {
  updateHistoryWithNewReport,
  updateHistoryWithNewResolution,
  updateHistoryWithNewStatus,
  updateHistoryWithNewVerdict,
} from '../../../Helpers/updateClaimHistory';

export const OPEN_CLAIM_CHAT = 'OPEN_CLAIM_CHAT';
export const CLOSE_CLAIM_CHAT = 'CLOSE_CLAIM_CHAT';
export const FETCH_CLAIM_INFO_REQUEST = 'FETCH_CLAIM_INFO_REQUEST';
export const FETCH_CLAIM_INFO_SUCCESS = 'FETCH_CLAIM_INFO_SUCCESS';
export const FETCH_CLAIM_INFO_FAILURE = 'FETCH_CLAIM_INFO_FAILURE';
export const FETCH_CLAIM_HISTORY_REQUEST = 'FETCH_CLAIM_HISTORY_REQUEST';
export const FETCH_CLAIM_HISTORY_SUCCESS = 'FETCH_CLAIM_HISTORY_SUCCESS';
export const FETCH_CLAIM_HISTORY_FAILURE = 'FETCH_CLAIM_HISTORY_FAILURE';
export const UPDATE_CLAIM_HISTORY = 'UPDATE_CLAIM_HISTORY';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const RELOAD = 'RELOAD';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const WEBSOCKET_CHAT_ADD_MESSAGE = 'WEBSOCKET_CHAT_ADD_MESSAGE';
export const SET_SUCCESS_AUTOMATIC_RESOLUTION = 'SET_SUCCESS_AUTOMATIC_RESOLUTION';
export const UPDATE_FEED = 'UPDATE_FEED';

export type SetSuccessAutomaticResolutionT = {
  type: typeof SET_SUCCESS_AUTOMATIC_RESOLUTION;
  data: boolean;
};

export type OpenClaimChatT = {
  type: typeof OPEN_CLAIM_CHAT;
  id: string;
  isArchived: boolean,
  userId: string
};

export type CloseClaimChatT = {
  type: typeof CLOSE_CLAIM_CHAT;
};

export type ReloadT = {
  type: typeof RELOAD;
};

export type FetchClaimInfoRequestT = {
  type: typeof FETCH_CLAIM_INFO_REQUEST;
};

export type FetchClaimInfoSuccessT = {
  type: typeof FETCH_CLAIM_INFO_SUCCESS;
  data: {
    claim: ClaimT;
    resolution: ResolutionT<any> | null;
    isArchived: boolean
  };
};

export type FetchClaimInfoFailureT = {
  type: typeof FETCH_CLAIM_INFO_FAILURE;
  error: string;
};

export type SetChatMessagesT = {
  type: typeof SET_CHAT_MESSAGES;
  data: ClaimChatMessagesT;
};

export type SetWebsocketsChatAddMessageT = {
  type: typeof WEBSOCKET_CHAT_ADD_MESSAGE;
  data: WebsocketChatMessageT;
};

export type FetchClaimHistoryRequestT = {
  type: typeof FETCH_CLAIM_HISTORY_REQUEST;
  id: string;
};

export type FetchClaimHistorySuccessT = {
  type: typeof FETCH_CLAIM_HISTORY_SUCCESS;
  claimHistory: Array<ClaimHistoryT>;
};

export type FetchClaimHistoryFailureT = {
  type: typeof FETCH_CLAIM_HISTORY_FAILURE;
  error: string;
};

export type UpdateClaimHistoryT = {
  type: typeof UPDATE_CLAIM_HISTORY;
  data: Array<ClaimHistoryT>;
};

export type UpdateClaimT = {
  type: typeof UPDATE_CLAIM;
  data: ClaimT;
};

export type UpdateFeedT = {
  type: typeof UPDATE_FEED;
  feed: IClaimFeed;
  claimId: string
};

export type StateT = {
  loading: boolean;
  loaded: boolean;
  error: null | string;
  claimId: string | null;
  verdict: VerdictT | null;
  claim: ClaimT | null;
  resolution: ResolutionT<any> | null;
  claimHistory: Array<ClaimHistoryT> | null;
  reload: number,
  chatMessages: ClaimChatMessagesT | null,
  websocketChatAddMessage: WebsocketChatMessageT | null,
  successAutomaticResolution: boolean,
  feedUpdateCounter: number
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  error: null,
  claimId: null,
  claim: null,
  verdict: null,
  resolution: null,
  claimHistory: null,
  reload: 0,
  chatMessages: null,
  websocketChatAddMessage: null,
  successAutomaticResolution: false,
  feedUpdateCounter: 0
};

type ActionTypeT =
  | OpenClaimChatT
  | CloseClaimChatT
  | WebsocketAssessmentTimeoutT
  | WebsocketClaimVerdictReceivedT
  | WebsocketClaimReportReceivedT
  | WebsocketClaimReportResolvedT
  | WebsocketClaimResolutionReceivedT
  | WebsocketNewClaimStatusReceivedT
  | FetchClaimInfoRequestT
  | FetchClaimInfoSuccessT
  | FetchClaimInfoFailureT
  | FetchClaimHistoryRequestT
  | FetchClaimHistorySuccessT
  | FetchClaimHistoryFailureT
  | ClaimRepairDropOffSuccessT
  | ClaimRepairPickUpSuccessT
  | WebsocketShipmentUpdateT
  | UpdateClaimHistoryT
  | ReloadT
  | WebsocketSetCatalogsT
  | WebsocketSetClaimT
  | UpdateClaimT
  | SetChatMessagesT
  | SetWebsocketsChatAddMessageT
  | SetSuccessAutomaticResolutionT
  | UpdateFeedT;

export default function claimChatReducer(
  state: StateT = initialState,
  action: ActionTypeT,
): StateT {

  switch (action.type) {

    case OPEN_CLAIM_CHAT: {

      if (typeof action.id !== 'string') {
        console.error('Invalid claim ID:', action.id);
        return state;
      }

      return {
        ...state,
        claimId: action.id,
      };
    }

    case UPDATE_CLAIM: {

      if (state.claim && state.claim.id !== action.data.id) {
        return state;
      }

      return {
        ...state,
        claim: {
          ...action.data,
          feed: action.data?.feed || state.claim.feed
        },
        claimHistory: null
        // claimHistory: action.data.cl
      };
    }

    case UPDATE_FEED: {

      if (state.claim && state.claim.id !== action.claimId) {
        return state;
      }

      return {
        ...state,
        claim: {
          ...state.claim,
          feed: {
            ...action.feed,
            unreadEntries: (state.claim?.feed?.unreadEntries || 0) + 1
          }
        },
        feedUpdateCounter: state.feedUpdateCounter + 1
      };
    }

    case SET_SUCCESS_AUTOMATIC_RESOLUTION: return {
      ...state,
      successAutomaticResolution: action.data
    }

    case RELOAD: {
      return {
        ...state,
        reload: state.reload + 1,
      };
    }
    case CLOSE_CLAIM_CHAT: {
      return {
        ...initialState,
      };
    }

    case WEBSOCKET_ASSESSMENT_TIMEOUT: {
      return { ...state, ...action.data };
    }

    case WEBSOCKET_SET_CLAIM:
      return {
        ...state,
        claim: action.data?.id === state.claim.id ? action.data : state.claim
      };

    case WEBSOCKET_CLAIM_VERDICT_RECEIVED: {
      return handleWebsocketClaimVerdictReceived(state, action);
    }
    case WEBSOCKET_CLAIM_REPORT_RECEIVED: {
      return handleWebsocketReportReceived(state, action);
    }
    case WEBSOCKET_CLAIM_REPORT_RESOLVED: {
      return handleWebsocketReportResolved(state, action);
    }
    case WEBSOCKET_CLAIM_RESOLUTION_RECEIVED: {
      return handleWebsocketResolutionReceived(state, action);
    }
    case WEBSOCKET_NEW_STATUS_RECEIVED: {
      return handleWebsocketNewClaimStatusReceived(state, action);
    }
    case WEBSOCKET_SHIPMENT_UPDATE: {
      // @ts-ignore
      return { ...state, resolution: { ...state.resolution, ...action.data } };
    }

    case WEBSOCKET_SET_CATALOGS: return {
      ...state,
      // @ts-ignore
      catalogs: action.data
    }

    case FETCH_CLAIM_INFO_REQUEST:
    case FETCH_CLAIM_HISTORY_REQUEST: {
      return loading<StateT>(state);
    }
    case FETCH_CLAIM_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        claim: action.data.claim,
        resolution: action.data.resolution,
      };
    }
    case FETCH_CLAIM_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        claimHistory: action.claimHistory,
      };
    }
    case FETCH_CLAIM_INFO_FAILURE:
    case FETCH_CLAIM_HISTORY_FAILURE: {
      return error<StateT>(state, action.error);
    }
    case CLAIM_REPAIR_PICK_UP_SUCCESS:
    case CLAIM_REPAIR_DROP_OFF_SUCCESS: {
      return {
        ...state,
        resolution: action.data,
      };
    }
    case UPDATE_CLAIM_HISTORY: {
      return { ...state, claimHistory: action.data };
    }
    case SET_CHAT_MESSAGES: return {
      ...state,
      chatMessages: action.data
    }

    case WEBSOCKET_CHAT_ADD_MESSAGE: return {
      ...state,
      websocketChatAddMessage: action.data
    }

    default: {
      return state;
    }
  }
}

export function openClaimChat(id: string, isArchived: boolean, userId: string): OpenClaimChatT {
  return {
    type: OPEN_CLAIM_CHAT,
    id,
    isArchived,
    userId
  };
}

export function closeClaimChat(): CloseClaimChatT {
  return {
    type: CLOSE_CLAIM_CHAT,
  };
}

export function fetchClaimInfoRequest(): FetchClaimInfoRequestT {
  return {
    type: FETCH_CLAIM_INFO_REQUEST,
  };
}

export function reload(): ReloadT {
  return {
    type: RELOAD,
  };
}

export function fetchClaimHistoryRequest(id: string): FetchClaimHistoryRequestT {
  return {
    type: FETCH_CLAIM_HISTORY_REQUEST,
    id,
  };
}

export function fetchClaimHistorySuccess(
  claimHistory: Array<ClaimHistoryT>,
): FetchClaimHistorySuccessT {
  return {
    type: FETCH_CLAIM_HISTORY_SUCCESS,
    claimHistory,
  };
}

export function fetchClaimHistoryFailure(error: string): FetchClaimHistoryFailureT {
  return {
    type: FETCH_CLAIM_HISTORY_FAILURE,
    error,
  };
}

export function fetchClaimInfoSuccess(data: FetchClaimInfoSuccessT['data']): FetchClaimInfoSuccessT {
  return {
    type: FETCH_CLAIM_INFO_SUCCESS,
    data,
  };
}

export function fetchClaimInfoFailure(error: string): FetchClaimInfoFailureT {
  return {
    type: FETCH_CLAIM_INFO_FAILURE,
    error,
  };
}

export const setChatMessages = (data: ClaimChatMessagesT | null): SetChatMessagesT => {
  return {
    type: SET_CHAT_MESSAGES,
    data,
  };
}

export const websocketChatAddMessage = (data: WebsocketChatMessageT | null): SetWebsocketsChatAddMessageT => {
  return {
    type: WEBSOCKET_CHAT_ADD_MESSAGE,
    data,
  };
}

export function updatedClaimHistory(data: Array<ClaimHistoryT>): UpdateClaimHistoryT {
  return {
    type: UPDATE_CLAIM_HISTORY,
    data,
  };
}

function getClaimData(state: StateT): ClaimT | never {
  if (!state.claim) {
    throw new Error('NO_ACTIVE_CLAIM');
  }

  return state.claim;
}

export function updateClaim(data: ClaimT): UpdateClaimT {
  return {
    type: UPDATE_CLAIM,
    data
  }
}

export function updateFeed(claimId: string, feed: IClaimFeed): UpdateFeedT {
  return {
    type: UPDATE_FEED,
    claimId,
    feed
  }
}

function checkAndReturnClaimIfActual(state: StateT, claimId: string): ClaimT | never {
  const claim = getClaimData(state);

  if (!claim || claim.id !== claimId) {
    throw new Error('NOT_ACTIVE_CLAIM');
  }

  return claim;
}

function handleWebsocketClaimVerdictReceived(
  state: StateT,
  action: WebsocketClaimVerdictReceivedT,
): StateT {
  try {
    checkAndReturnClaimIfActual(state, action.data.claimId);
    const claimHistory: Array<ClaimHistoryT> | [] = updateHistoryWithNewVerdict(state, action.data);
    return {
      ...state,
      verdict: action.data,
      claimHistory,
    };
  } catch {
    return state;
  }
}

function handleWebsocketReportResolved(
  state: StateT,
  action: WebsocketClaimReportResolvedT,
): StateT {
  try {
    const { reportId, claimId } = action.data;
    const claim = checkAndReturnClaimIfActual(state, claimId);

    return {
      ...state,
      claim: {
        ...claim,
        reports: claim.reports.map((report: ReportT) =>
          report.id !== reportId ? report : { ...report, isResolved: true },
        ),
      },
    };
  } catch {
    return state;
  }
}

function handleWebsocketReportReceived(
  state: StateT,
  action: WebsocketClaimReportReceivedT,
): StateT {
  try {
    const claim = checkAndReturnClaimIfActual(state, action.data.claimId);
    const claimHistory = updateHistoryWithNewReport(state, action.data);
    return {
      ...state,
      claim: {
        ...claim,
        reports: [...claim.reports, action.data],
      },
      claimHistory,
    };
  } catch {
    return state;
  }
}

function handleWebsocketNewClaimStatusReceived(
  state: StateT,
  action: WebsocketNewClaimStatusReceivedT,
): StateT {
  try {
    const claim = checkAndReturnClaimIfActual(state, action.data.claimId);
    const claimHistory: any = updateHistoryWithNewStatus(state, action.data);
    return {
      ...state,
      claim: {
        ...claim,
        claimStatus: [action.data, ...(claim.claimStatus || [])],
        closed:
          [CLAIM_STATUSES.CLOSED, CLAIM_STATUSES.CANCELLED].includes(action.data.status) ||
          claim.closed,
      },
      claimHistory,
    };
  } catch {
    return state;
  }
}

function handleWebsocketResolutionReceived(
  state: StateT,
  action: WebsocketClaimResolutionReceivedT,
): StateT {
  try {
    const claim = checkAndReturnClaimIfActual(state, action.data.claimId);
    const claimHistory = updateHistoryWithNewResolution(state, action.data);
    return {
      ...state,
      claim,
      claimHistory,
    };
  } catch {
    return state;
  }
}

export const socketsSuccessAutomaticResolution = (data: boolean) => {
  return {
    type: SET_SUCCESS_AUTOMATIC_RESOLUTION,
    data
  };
}
