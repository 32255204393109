import React from 'react';

import style from './DocumentsOriginal.module.scss';
import { StateLocalT } from '../../CreateForm';
import useReportTagTranslation from 'Hooks/useOriginalTags';

type MainProps = {
    state: StateLocalT,
    t: (data: string, params?: any) => string
}


const DocumentsOriginal: React.FC<MainProps> = ({ state, t }) => {
    const getTitle = useReportTagTranslation(t);
    return (
        <div className={style.root}>
            <div className={style.header}>
                {t('createClaim.origianDocumentsContent')}
            </div>
            <ul>
                {state.requiredMissingDocuments.filter(doc => doc.isOriginal__c && (!doc.Set__c || !(!!doc.Set__c && state.requiredMissingDocuments.some(d => !!d.Set__c && d.Set__c === doc.Set__c && !d.isOriginal__c)))).map(doc => (
                    <li key={`doc_${doc.Id}`}>{getTitle(doc.Name)}</li>
                ))}
            </ul>
        </div>
    );
}
export default DocumentsOriginal;
