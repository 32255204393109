import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, IconButton, List } from 'rsuite';
import { useNavigate } from "react-router";
import parse from 'html-react-parser';

import BackIcon from '@rsuite/icons/ArowBack';
import CloseIcon from '@rsuite/icons/Close';
import InfoIcon from '@rsuite/icons/InfoOutline';
import HelpIcon from '@rsuite/icons/HelpOutline';
import AssignmentIcon from '@rsuite/icons/Task';
import LanguageIcon from '@rsuite/icons/Global';
import LogoutIcon from '@rsuite/icons/Exit';
import LoginIcon from '@rsuite/icons/UserChange';
import CookieIcon from '@rsuite/icons/Cookie';
import SearchDataLiveIcon from '@rsuite/icons/SearchDataLive';

import PersonalInformationMain from 'PageComponents/Settings/PersonalInformation/PersonalInformation';
import { AccountSettingsValueT, setAccountSettings } from 'Store/modules/accountSettings';
import HelpCenterMain from 'PageComponents/Settings/HelpCenter/HelpCenter';
import { Flag } from "Components/RegistrationForm/PhoneNumber/PhoneNumber";
import runApi, { ApiSendMethods, ResultApiTypeT } from 'api/base/runApi';
import { ReactComponent as BurgerMenu} from 'Assets/BurgerMenu.svg'; 
import { useDeviceType } from "Helpers/responsiveContainers";
import { clearCart } from 'Store/modules/luggageStore/shop';
import { UrlActionTypeT } from 'Components/Header/Header';
import { StateT as UserStateT } from 'Store/modules/user';
import { ReactComponent as Logo } from 'Assets/logo.svg';
import useChangeLanguage from 'Hooks/useChangeLanguage';
import DrawerHeader from './DrawerHeader/DrawerHeader';
import DrawerOption from "./DrawerOption/DrawerOption";
import { updateUserReset } from 'Store/modules/user';
import { logout } from 'Store/modules/authorization';
import { actionShowCookies } from "Helpers/browser";
import { runNotify } from 'Helpers/notifications';
import { setUserData } from 'Store/modules/user';
import useTranslate from 'Hooks/useTranslate';
import styles from './DrawerMenu.module.scss';
import { languagePivot } from "Helpers/chat";
import { AppStateT } from 'Store/modules';

type DrawerMenuProps = {
    open: boolean;
    toggleDrawer: (open: boolean) => void;
    footerLinksFirstRow?: { name: string; path: string }[];
    footerLinksSecondRow?: { name: string; path: string }[];
    bigMenuButton?: boolean
};

enum contentType {
    Languages = "Languages",
    Terms = "Terms",
    HelpCenter = "HelpCenter",
    PersonalInformation = "PersonalInformation",
    Privacy = "Privacy"
}

const iconSize = 16;

const DrawerMenu: React.FC<DrawerMenuProps> = ({ open, toggleDrawer, footerLinksFirstRow, footerLinksSecondRow, bigMenuButton }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const t = useTranslate();
    const { sortedTranslatedLanguages, changeAppLanguage } = useChangeLanguage();
    const[isContent, setContent] = useState<contentType>(null);
    const setting = useSelector<AppStateT, AccountSettingsValueT>((state) => state.accountSettings.setting);
    const [helpUrl, setHelpUrl] = useState('https://www.dolfi1920.de/faqs.html');
    const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
    const [saving, setSaving] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const { accountInfo } = useSelector<AppStateT, UserStateT>((state) => state.user);
    const { isMobile } = useDeviceType();
    const sizeButton = useMemo(() => bigMenuButton ? 35 : 25, [bigMenuButton]);

    const isLoginUser = (): boolean => {
        return !!(localStorage.getItem('token') || '');
    }
    const resetPersonalInfoFormState = useCallback(() => {
        dispatch(updateUserReset());
    }, [dispatch]);

    interface IUserSelector {
        user: UserStateT['data'];
        firstTimeLogin: UserStateT['firstTimeLogin'];
    }
    const { user } = useSelector<AppStateT, IUserSelector>(({ user }) => ({
        user: user.data,
        firstTimeLogin: user.firstTimeLogin
    }));

    const changePersonalInfo = useCallback((personalInfo) => {
        setSaving(true);
        runApi('user', personalInfo, (r: ResultApiTypeT) => {
            if (r.result){
                dispatch(setUserData(r.data));
                runNotify({ message: t('common.updateChanged'), type: 'success' });
            } else {
                runNotify({ message: r.error?.message || t('registration.errorRegister'), type: 'error' });
                if (r.error.code === 'USER_EMAIL_EXIST'){
                    setErrorEmail(true);
                }
            }
            setSaving(false);
        }, ApiSendMethods.patch);
    }, [dispatch, t]);

    const languageMap = useMemo(() => ({
        'de': 'german',
        'cs-cz': 'czech',
        'pt-pt': 'portuguese',
        'it': 'italian',
        'hu': 'hungarian',
        'jp': 'japanese',
        'sk': 'slovak',
        'es': 'spanish',
        'kr': 'korean',
        'cn': 'chinese',
        'fr': 'french',
        'lv': 'latvian',
        'nl': 'dutch'
    }), []);
    
    const generateHelpUrl = useCallback(() => {
        const language = actualLanguage;
    
        if (language && languageMap[language]) {
            return `https://www.dolfi1920.de/faqs.html?___store=de_eur_${languageMap[language]}`;
        }
    
        return 'https://www.dolfi1920.de/faqs.html';
    },[languageMap, actualLanguage]);

    useEffect(() => {
        setHelpUrl(generateHelpUrl());
    }, [generateHelpUrl]);

    const closeContent = useCallback(() => {
        setContent(null)
    }, []);
    
    const chooseSetting = useCallback((value: AccountSettingsValueT) => {
        dispatch(setAccountSettings(value));
    }, [dispatch])

    const logoutHandler = useCallback(() => {
        dispatch(logout());
        dispatch(clearCart(true));
        window.location.href = '/';
    }, [dispatch]);

    const actionSelectLanguage = useMemo(() => (event: React.SyntheticEvent, languageValue: string) => {
        event.preventDefault();
        changeAppLanguage(languageValue);
        toggleDrawer(false);
        closeContent();
        if (isLoginUser()) {runApi('user', {language: languageValue} , null, ApiSendMethods.patch)};    
    }, [changeAppLanguage, toggleDrawer, closeContent]);

    const renderIcon = useMemo(() => (path: string) => {
        if (path.includes('/login')) return <LoginIcon width={iconSize} height={iconSize} />;
        if (path.includes('/about')) return <InfoIcon width={iconSize} height={iconSize} />;
        if (path.includes('/help')) return <HelpIcon width={iconSize} height={iconSize} />;
        if (path.includes('/lang')) return <LanguageIcon width={iconSize} height={iconSize} />;
        if (path.includes('/privacyPolicy')) return <SearchDataLiveIcon width={iconSize} height={iconSize} />;
        if (path.includes('/privacy')) return <CookieIcon width={iconSize} height={iconSize} />;
        if (path.indexOf('/personalInfo') === 0) return <InfoIcon width={iconSize} height={iconSize} />;
        if (path.includes('/terms')) return <AssignmentIcon width={iconSize} height={iconSize} />;
        if (path.includes('/logOut')) return <LogoutIcon width={iconSize} height={iconSize} />;
        return null;
    }, []);

    const getActionType = useMemo(() => (path: string): UrlActionTypeT => {
        if (path.includes('/login')) return 'login';
        if (path.includes('/about')) return 'about';
        if (path.includes('/help')) return 'help';
        if (path.includes('/lang')) return 'language';
        if (path.includes('/privacyPolicy')) return 'privacyPolicy';
        if (path.includes('/privacy')) return 'privacy';
        if (path.indexOf('/personalInfo') === 0) return 'personalInfo';
        if (path.includes('/terms')) return 'terms';
        if (path.includes('/logOut')) return 'logOut';
        return 'login';
    }, []);

    const handleClickMenu = useMemo(() => (event: React.MouseEvent, path: string) => {
        const actionType = getActionType(path);
        event.preventDefault();
        if (actionType === 'language') {
            setContent(contentType.Languages);
            return;
        }
        if (actionType === 'terms') {
            setContent(contentType.Terms);
            return;
        }
        if (actionType === 'help') {
            setContent(contentType.HelpCenter);
            return;
        }
        if (actionType === 'logOut') {
            logoutHandler();
            toggleDrawer(false);
            return;
        }
        if (actionType === 'personalInfo') {
            setContent(contentType.PersonalInformation);
            return;
        }
        if (actionType === 'privacyPolicy') {
            setContent(contentType.Privacy);
            return;
        }

        toggleDrawer(false);
    
        setTimeout(() => {
            if (actionType === 'login') {
                navigate('/login');
            } else if (actionType === 'about') {
                navigate('/about');
            } else if (actionType === 'privacy') {
                actionShowCookies();
            }
        }, 200);
    }, [getActionType, logoutHandler, navigate, toggleDrawer]);

    return (
        <>
            <BurgerMenu style={{ width: sizeButton, height: sizeButton }} color={"#818181"} onClick={() => toggleDrawer(true)} className={styles.menuIcon}/>
            <Drawer open={open} 
                onClose={() => {
                    toggleDrawer(false); 
                    closeContent();
                }}
                placement="right"
                size={isMobile ? 'full' : ([contentType.Terms, contentType.Privacy, contentType.HelpCenter].includes(isContent)? '700px' : '400px')}
            >
                <div className={styles.headerDrawer}>
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                    <div className={styles.buttons}>
                        {isContent &&
                            <IconButton
                                icon={<BackIcon />}
                                onClick={closeContent}
                                appearance={'subtle'}
                                className={styles.circleButton}
                            />
                        }
                        <IconButton
                            icon={<CloseIcon />}
                            onClick={() => {
                                toggleDrawer(false);
                                closeContent();
                            }}
                            appearance={'subtle'}
                            className={styles.circleButton}
                        />
                    </div>
                </div>
                <div className={styles.contentScrollable}>
                    {isContent=== null && (
                        <List hover>
                            {actualLanguage !== 'pl' && actualLanguage !== 'en-us' && (
                                <DrawerOption
                                    onClick={() => window.open(helpUrl)}
                                    title={t('footer.help')}
                                    icon={<HelpIcon width={iconSize} height={iconSize}/>}
                                />
                            )}
                            {footerLinksFirstRow?.map(({ name, path }) => (
                                <DrawerOption
                                    onClick={(event) => handleClickMenu(event, path)}
                                    title={path.includes('/lang') && actualLanguage !== 'en-us' ? `${t(name)} (Select language)` : t(name)}
                                    icon={renderIcon(path)}
                                    key={`f-${name}-${path}`}
                                />
                            ))}
                            {footerLinksSecondRow?.map(({ name, path }) => (
                                <DrawerOption
                                    onClick={(event) => handleClickMenu(event, path)}
                                    title={t(name)}
                                    icon={renderIcon(path)}
                                    key={`fl-${name}-${path}`}
                                />
                            ))}
                        </List>
                    )}
                    {isContent === contentType.Languages && ( 
                        <List hover>
                            <DrawerHeader title={t('labels.selectLanguage')}/>
                            {actualLanguage !== 'en-us' && <div className={styles.langAdditionInfo}>(Select language)</div>}
                            {sortedTranslatedLanguages.map((language) => (
                                <DrawerOption
                                    title={language.displayName}
                                    onClick={(event) => actionSelectLanguage(event, language.value)}
                                    icon={<div className={styles.flag}><Flag code={languagePivot(language.value).toUpperCase()} title={language.displayName} /></div>}
                                    isIcoOpacity
                                    selected={actualLanguage === language.value}
                                    key={language.value}
                                />
                            ))}
                        </List>
                    )}
                    {isContent === contentType.Terms && (
                        <>
                            <DrawerHeader title={t('footer.termsAndConditions')}/>
                            <div>
                                {parse(t('regs.regulations'))}
                            </div>
                        </>
                    )}     
                    {isContent === contentType.HelpCenter && (
                        <>
                            <DrawerHeader title={t('common.helpCenter')}/>
                            <HelpCenterMain
                                chooseSetting={chooseSetting}
                                setting={setting}
                            />
                        </>
                    )}
                    {isContent === contentType.PersonalInformation && (
                        <>
                            <DrawerHeader title={t('common.personalInformation')}/>
                            <PersonalInformationMain
                                userData={user}
                                onSubmit={changePersonalInfo}
                                submitted={accountInfo.loaded}
                                error={accountInfo.error}
                                reset={resetPersonalInfoFormState}
                                isSubmitting={saving}
                                errEmail={errorEmail}
                                setErrEmail={setErrorEmail}
                            />
                        </>
                    )}
                    {isContent === contentType.Privacy && (
                        <>
                            <DrawerHeader title={t('footer.privacyPolicy')}/>  
                            <div className={styles.spanSt} >
                                {parse(t('regs.privacy'))}
                            </div>
                        </>
                    )}
                </div>
            </Drawer>
        </>
    );
};

export default DrawerMenu;
