import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { changeModalTooltip, dataModalArticleT } from 'Store/modules/page';
import { AppStateT } from 'Store/modules';
import { isHtml } from 'Helpers/strings';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import styles from './Tooltip.module.scss';
import CustomModal from 'Components/CustomModal/CustomModal';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';

/*
 * Show Modal tooltip
 * data from showModalTolltip (pure data title and content or from prismic)
 */
const Tooltip = () => {

    const showModalTooltip = useSelector<AppStateT, dataModalArticleT|null>((state) => state.page.showModalTolltip);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeAction = useCallback(() => {
        dispatch(changeModalTooltip(null))
    }, [dispatch]);

    useLayoutEffect(() => {
        if (showModalTooltip){

            const listenerKeyEscape = (event: KeyboardEvent) => {
                if (event.key === 'Escape'){
                    closeAction();
                }
            }

            document.addEventListener("keydown", listenerKeyEscape);

            return () => {
                document.removeEventListener("keydown", listenerKeyEscape);
            }
        }
    }, [showModalTooltip, closeAction]);

    const Content = useCallback(() => {
        if (showModalTooltip?.pagesContentKey){
            const data = t(showModalTooltip.pagesContentKey);
            return isHtml(data) ? parse(t(showModalTooltip.pagesContentKey)) : t(showModalTooltip.pagesContentKey);
        }
        return showModalTooltip?.content;
    }, [showModalTooltip, t])

    const Title = useMemo(() => {
        return showModalTooltip?.title ? t(showModalTooltip.title) : (showModalTooltip?.title || '');
    }, [showModalTooltip, t]);

    return (
        <CustomModal
            open={!!showModalTooltip}
            >
            <CustomModalHeader
                title={Title}
                onClose={closeAction}
            />
            <Modal.Body className={styles.customModalBody}>
            <div style={{ paddingBottom: 15 }}>
                {Content()}
                {showModalTooltip?.object && <>{showModalTooltip.object}</>}
            </div>
            </Modal.Body>
        </CustomModal>
    )
}

export default Tooltip;