import { fork, put, call, takeEvery, retry } from 'redux-saga/effects';
import api from 'api';
import {
  OpenClaimChatT,
  FetchClaimInfoSuccessT,
  OPEN_CLAIM_CHAT,
  FETCH_CLAIM_INFO_SUCCESS,
  fetchClaimInfoRequest,
  fetchClaimInfoSuccess,
  fetchClaimInfoFailure,
  fetchClaimHistoryRequest,
} from 'Store/modules/claimChat/data';
import { clearNotificationCount } from 'Store/modules/claim';
import { verdictResolutionAdapter } from 'Helpers/adapters';
import { sendSentryError } from "services/errors";

function* fetchClaimInfoSaga(claimId: string, isArchived: boolean, userId: string) {
  try {
    yield put(fetchClaimInfoRequest());
    yield put(fetchClaimHistoryRequest(claimId));

    const { data: claimRawData } = yield call(api().claim.getById, claimId, isArchived);
    
    if (!claimRawData || (!!claimRawData && claimRawData.userId !== userId)){
      sendSentryError(`Wrong user claim [User id: ${userId}]`, claimRawData, 'error');
      yield put(fetchClaimInfoFailure('Wrong userId'));
      return;
    }

    let { verdict, resolution, ...claim } = claimRawData;
    let { reports } = claim;

    reports.sort(
      (a: ReportT, b: ReportT) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    if (verdict) {
      verdict = verdictResolutionAdapter(verdict);
    }

    yield put(fetchClaimInfoSuccess({ claim: claimRawData, resolution, isArchived }));
  } catch (err) {
    console.log(err);
    yield put(fetchClaimInfoFailure(err));
  }
}

// function* openClaimChatSaga({ id: claimId, isArchived }: OpenClaimChatT) {
function* openClaimChatSaga(data: OpenClaimChatT) {
  yield fork(fetchClaimInfoSaga, data.id, data.isArchived, data.userId);
}

function* markChatAsReadSaga(action: FetchClaimInfoSuccessT) {
  try {
    const claimId = action.data.claim.id;
    const isArchived = action.data.isArchived;
    yield put(clearNotificationCount(claimId));
    yield retry(3, 0, api().claim.readAll, claimId, isArchived);
  } catch (error) {
    // Retry?
  }
}

export default function* claimChat() {
  yield takeEvery(OPEN_CLAIM_CHAT, openClaimChatSaga);
  yield takeEvery(FETCH_CLAIM_INFO_SUCCESS, markChatAsReadSaga);
}
