import React from 'react';
import { IconButton } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close'; 
import styles from './CustomModalHeader.module.scss'

interface CustomModalHeaderProps {
  title?: string | React.ReactNode; 
  searchInput?: React.ReactNode;
  onClose: () => void; 
  leftLabelClassName?: string; 
  rightLabelClassName?: string; 
  children?: React.ReactNode; 
  fontColor?: string
}

const CustomModalHeader: React.FC<CustomModalHeaderProps> = ({
  title,
  searchInput,
  onClose,
  leftLabelClassName,
  rightLabelClassName,
  children,
  fontColor='black'
}) => {
  return (
    <div className={styles.customModalHeader} style={{ '--fontColor': fontColor } as React.CSSProperties}>
      <div className={`${styles.leftLabel} ${leftLabelClassName || ''}`}>
        {title} {searchInput}
      </div>
      <div
        className={`${styles.rightLabel} ${rightLabelClassName || ''}`}
        onClick={onClose}
      >
        <IconButton
          className={styles.circleButton}
          icon={<CloseIcon />}
          onClick={onClose}
          appearance="subtle"
        />
      </div>
      {children && <div className={styles.additionalContent}>{children}</div>}
    </div>
  );
};

export default CustomModalHeader;
