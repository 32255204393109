import { useDispatch, useSelector } from 'react-redux';
import { validField } from 'Helpers/validForm';
import { useTranslation } from 'react-i18next';

import { changeShopCounter, setDelivery, ShopStateT } from 'Store/modules/luggageStore/shop';
import ClientAdress from '../ShoppingCartMainScreen/ClientAdress/ClientAdress';
import styles from './StoreCartMobileDeliveryForm.module.scss';
import { Button, Switcher } from '../../../Components';
import { useCallback, useEffect, useState } from 'react';
import { AppStateT } from 'Store/modules';
import { runNotify } from 'Helpers/notifications';

interface IStoreCartMobileDeliveryForm {
    open: boolean;
    onClose: () => void;
}

export default function StoreCartMobileDeliveryForm({ open, onClose }: IStoreCartMobileDeliveryForm) {

    const { delivery, claim } = useSelector<AppStateT, ShopStateT>((state) => state.store.shop);
    const [valid, setValid] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [focusValue, setFocusValue] = useState('');
    const [focusValueDelivery, setFocusValueDelivery] = useState('');

    const checkValid = useCallback(() => {
        const adressFieleds = ['name', 'surname', 'street', 'city', 'zipCode'];
        for(let i=0;i<adressFieleds.length;i++){
            if (!validField(`clientAddress-${adressFieleds[i]}`, delivery.clientAddress[adressFieleds[i]])){
                return setValid(false);
            }
        }
        if (!delivery.clientAddressDeliveryOn){
            for(let i=0;i<adressFieleds.length;i++){
                if (!validField(`clientAddressDelivery-${adressFieleds[i]}`, delivery.clientAddressDelivery[adressFieleds[i]])){
                    return setValid(false);
                }
            }
        }
        setValid(true);
    }, [delivery]);


    useEffect(() => {
        checkValid();
    },[delivery.clientAddress, delivery.clientAddressDelivery, delivery.clientAddressDeliveryOn, checkValid]);

    const actionChange = (type: string, data: string, addressType: string = 'clientAddress') => {
        dispatch(setDelivery({
            ...delivery,
            [addressType]: {
                ...delivery.clientAddress,
                [type]: data
            }
        }));
    }

    const actionDeliveryChange = (type: string, data: string, addressType: string = 'clientAddress') => {
        dispatch(setDelivery({
            ...delivery,
            [addressType]: {
                ...delivery.clientAddressDelivery,
                [type]: data
            }
        }));
    }

    const actionCopyDataOrigin = () => {
        dispatch(setDelivery({
            ...delivery,
            clientAddressDelivery: {
                ...delivery.clientAddress,
            }
        }));
        runNotify({ message: t('shop.buttonCopyContactSuccess'), type: 'success' });
    }

    const blurActionPayment = (type: string) => {
        if (focusValue !== delivery.clientAddress[type]){
            dispatch(changeShopCounter());
        }
    }

    const blurActionPaymentDelivery = (type: string) => {
        if (focusValueDelivery !== delivery.clientAddressDelivery[type]){
            dispatch(changeShopCounter());
        }
    }

    return (
        // <Modal open={open} onClose={onClose} title={t('shop.delivery')} back={onClose} contentClassName={styles.root}>
        <>
            <ClientAdress
                actionChange={actionChange}
                clientData={delivery.clientAddress}
                clientAddressType={'clientAddress'}
                validField={validField}
                mobile
                countryName={claim?.location.country}
                blurAction={blurActionPayment}
                t={t}
                setFocusValue={setFocusValue}
            />

            <div className={styles.chooseAddressContainer}>
                <div className={styles.theSameAdrdessText}>
                    {t('shop.deliveryTheSame')}
                </div>

                <div className={styles.switcher}>
                    <Switcher
                        name="deliveryBillingTheSame"
                        value={delivery.clientAddressDeliveryOn}
                        onChange={() => {
                            dispatch(setDelivery({ ...delivery, clientAddressDeliveryOn: !delivery.clientAddressDeliveryOn }));
                            dispatch(changeShopCounter())
                        }}
                    />
                </div>
            </div>
            {!delivery.clientAddressDeliveryOn &&
                <ClientAdress
                    actionChange={actionDeliveryChange}
                    clientData={delivery.clientAddressDelivery}
                    clientAddressType={'clientAddressDelivery'}
                    validField={validField}
                    mobile
                    countryName={claim?.location.country}
                    blurAction={blurActionPaymentDelivery}
                    t={t}
                    shipping
                    actionCopyDataOrigin={actionCopyDataOrigin}
                    setFocusValue={setFocusValueDelivery}
                />
            }

            <div className={styles.buttonContainer}>
                <Button type="submit" className={styles.btn} onClick={onClose} disabled={!valid}>
                    {t('common.confirm')}
                </Button>
            </div>
            </>
        // </Modal>
    );
}
