import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'Assets/calendar.svg';
import ImgRimowa from 'Assets/new_claim/serial_number_rimowa.png';
import ImgTumi from 'Assets/new_claim/serial_number_tumi.png';

import { formatDateMonth, getDaysAgo, isCurrentMonth } from 'Helpers/date';
import DropDownSearch from 'Components/DropDownSearch/DropDownSearch';
import { PageConfigT, changeModalTooltip } from 'Store/modules/page';
import { type StateT as UserStateT } from 'Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import { capitalizeFirstLetter } from 'Helpers/strings';
import { countriesCodes } from 'Helpers/languageCode';
import { StateLocalT } from '../../CreateForm';
import { defaultLanguage } from "Helpers/i18";
import { Input, Switcher } from 'Components';
import { sizeAdapter } from 'Helpers/claim';
import { AppStateT } from 'Store/modules';
import style from './Details.module.scss';
import Ico from 'Components/Ico/Ico';

type MainProps = {
    state: StateLocalT,
    setValidStep: (result: boolean) => void,
    updateClaim: (dataType: string, data: any) => void
}

export type SizesShowT = 'height'|'length'|'capacity'|'full'|'functions'|null;

type BrandT = {
    value: string,
    text: string,
    disabled?: boolean|undefined
}

const requireSerialNumberBrands = ['Rimowa', 'Tumi'];
const rejectedBrands = ['10639', '10711'];
const sizeRequiredLanguages = [ 'cs-cz', 'cz' ];

const Details: React.FC<MainProps> = ({ state, setValidStep, updateClaim }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const [sizesShow, setSizesShow] = useState<SizesShowT>(null);
    const language = useSelector<AppStateT, string>((state) => {
        const languageCode = countriesCodes.find((code) => state.i18n.language === code.countryCode)
        return languageCode?.localeCode || defaultLanguage
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { details } = state.claimData;
    const { isMobile } = useDeviceType();
    const [brands, setBrands] = useState(state.brands.map(br => {
        return { value: br, text: capitalizeFirstLetter(br === 'Other Brand' ? t('createClaim.otherBrand') : br) };
    }));
    const [sizes] = useState(Object.entries(pageConfig.claim.sizes).map(size => {
        return { value: size[0], text: size[0] };
    }));
    const { tumiSerialNumber, rimowaSerialNumber } = pageConfig.inputs;
    const serialNumber = details.serialNumber;
    const detailsBrand = details.brand;

    useEffect(() => {
        setBrands(state.brands.map(br => {
            return { value: br, text: capitalizeFirstLetter(br === 'Other Brand' ? t('createClaim.otherBrand') : br) };
        }));
    }, [state.brands, t]);

    const validTumiSerialNumber = useMemo(() => (): boolean => {
        return capitalizeFirstLetter(detailsBrand) === 'Tumi' && serialNumber.length === tumiSerialNumber.maxLength && !!serialNumber.match(tumiSerialNumber.regexp)
    }, [serialNumber, tumiSerialNumber.regexp, tumiSerialNumber.maxLength, detailsBrand])

    const validRimowaSerialNumber = useMemo(() => (): boolean => {
        return capitalizeFirstLetter(detailsBrand) === 'Rimowa' && serialNumber.length >= rimowaSerialNumber.minLength && serialNumber.length <= rimowaSerialNumber.maxLength && !!serialNumber.match(rimowaSerialNumber.regexp)
    }, [serialNumber, rimowaSerialNumber.regexp, rimowaSerialNumber.minLength, rimowaSerialNumber.maxLength, detailsBrand])

    useEffect(() => {
        let valid = true;
        if (
            (capitalizeFirstLetter(detailsBrand) === 'Rimowa' && !validRimowaSerialNumber) ||
            (capitalizeFirstLetter(detailsBrand) === 'Tumi' && !validTumiSerialNumber()) ||
            !brands.some(b => b.value.toLowerCase() === detailsBrand.toLowerCase()) || 
            !state.claimData.details.date || 
            (sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) && !state.claimData.details.size) ||
            (state.claimData.details.costKnow && (isNaN(+state.claimData.details.cost) ? 0 : +state.claimData.details.cost) <=0.9))
        {
            valid = false;
        }

        setValidStep(valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sizesShow,
        detailsBrand,
        serialNumber,
        state.requiredMissingDocuments,
        state.claimData.details.date,
        brands,
        state.claimData.details.cost,
        state.claimData.details.costKnow,
        user.location.countryCode,
        state.claimData.details.size
    ]);

    useEffect(() => {
        switch(state.claimData.luggageType){
            case 'Sport luggage': case "Sport":  case 'Music': return setSizesShow('functions');
            case 'Luggage': return setSizesShow('height');
            case 'Reisetasche': case 'Special luggage': return setSizesShow('length');
            case 'Backpack': return setSizesShow('capacity');
            case 'Baby stroller': case 'stroller': return setSizesShow(null);
            default: return setSizesShow('full');
        }
    }, [state.claimData.luggageType]);

    const actionChangeBrand = useCallback((brand: BrandT) => {
        updateClaim('details', {
            ...state.claimData.details,
            brand: capitalizeFirstLetter(brand.value)
        });
    }, [state.claimData.details, updateClaim])

    const actionChangeModel = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateClaim('details', {
            ...state.claimData.details,
            model: event.target.value
        });
    }, [state.claimData.details, updateClaim]);

    const actionChangeSerialNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateClaim('details', {
            ...state.claimData.details,
            serialNumber: event.target.value
        });
    }, [state.claimData.details, updateClaim])

    const onChangeCalendar = useCallback((date: any) => {
        setShowDatePicker(false);
        if (date && !isCurrentMonth(date)){
            date.setDate(16);
        } else if (date){
            date.setDate(2);
        }
        updateClaim('details', {
            ...state.claimData.details,
            date
        });
    }, [state.claimData.details, updateClaim])

    const actionChangeCost = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let cost = event.target.value.trim().replace(',', '.');
        const dataExp = cost.split('.');
        if (dataExp[1] && dataExp[1].length > 2){
            cost = `${dataExp[0]}.${dataExp[1].slice(0,2)}`
        }
        updateClaim('details', {
            ...state.claimData.details,
            cost: cost.replace(/[^0-9 .]/g, '')
        });
    }, [state.claimData.details, updateClaim]);

    const actionChangeCurrency = useCallback((data: string) => {
        updateClaim('details', {
            ...state.claimData.details,
            currency: data
        });
    }, [state.claimData.details, updateClaim])

    const toolTipBrand = (brand: string) => {
        if (brand === 'rimowa'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrRimowaDesc'),
                object: <div className={style.toolTipImage}><img src={ImgRimowa} alt="" /></div>
            }));
        } else if (brand === 'tumi'){
            dispatch(changeModalTooltip({ 
                title: t('createClaim.detTipSerialNr'), 
                content: t('createClaim.detTipSerialNrDescTumi'),
                object: <div className={style.toolTipImage}><img src={ImgTumi} alt="" /></div>
            }));
        }
    }

    const setSwitchCost = () => {
        updateClaim('details', {
            ...state.claimData.details,
            costKnow: !state.claimData.details.costKnow
        });
    };

    const actionChangeSize = (value: any) => {
        updateClaim('details', {
            ...state.claimData.details,
            size: value.value
        });
    }

    return (
        <div className={style.root}>
            {showDatePicker && <div className={style.maskDatePicker} onClick={() => setShowDatePicker(false)} />}

            <DropDownSearch
                data={[...brands.filter(brand => brand.value !== 'Other Brand' && !rejectedBrands.includes(brand.value)).sort(), ...brands.filter(brand => brand.value === 'Other Brand')]}
                onChange={actionChangeBrand}
                label={t('createClaim.detBrand')}
                value={detailsBrand === 'Other Brand' ? t('createClaim.otherBrand') : detailsBrand}
                className={style.inputLayer}
                search
                t={t}
                id={'search_new_claim_brand'}
            />
            <div className={cx(style.labelFieldAbove, { [style.error]: !brands.some(brand => brand.value.toLowerCase() === detailsBrand.toLowerCase()), [style.good]: brands.some(brand => brand.value === detailsBrand) })}>{t('common.requiredField')}</div>
            <Input
                label={t('createClaim.detModel')}
                value={state.claimData.details.model}
                onChange={actionChangeModel}
                className={style.inputLayer}
                id={'input_new_claim_model'}
            />
            <div className={style.labelFieldAbove}>{t('common.fieldOptional')}</div>
            {(requireSerialNumberBrands.length > 0 && requireSerialNumberBrands.includes(capitalizeFirstLetter(detailsBrand))) &&
                <>
                    <div className={cx(style.serialNumber, { [style.serialNumberRows]: capitalizeFirstLetter(detailsBrand) === 'Rimowa' || capitalizeFirstLetter(detailsBrand) === 'Tumi'})}>
                        <Input
                            label={t('createClaim.detSerialNumber')}
                            value={serialNumber}
                            onChange={actionChangeSerialNumber}
                            className={style.inputLayer}
                            id={'input_new_claim_serial_number'}
                            maxLength={capitalizeFirstLetter(detailsBrand) === 'Tumi' ? pageConfig.inputs.tumiSerialNumber.maxLength : pageConfig.inputs.rimowaSerialNumber.maxLength}
                        />
                        <div className={style.icoInfo}>
                            <span className={style.headerIco}><Ico action={() => toolTipBrand(detailsBrand.toLowerCase())} /></span>
                        </div>
                    </div>
                    <div className={cx(style.labelFieldAbove, { [style.error]: capitalizeFirstLetter(detailsBrand) === 'Rimowa' ? !validRimowaSerialNumber() : !validTumiSerialNumber(),
                        [style.good]: capitalizeFirstLetter(detailsBrand) === 'Rimowa' ? validRimowaSerialNumber() : validTumiSerialNumber() })}>{t('common.requiredField')}
                    </div>
                </>
            }

            <DropDownSearch
                data={sizes.map(size => {
                    return {
                        text: sizeAdapter(size.value, user.location.countryCode, user.language),
                        value: size.value
                    };
                })}
                onChange={actionChangeSize}
                label={t('shop.size')}
                value={sizeAdapter(state.claimData.details.size, user.location.countryCode, user.language)}
                className={style.inputLayer}
                t={t}
                hasCrossIcon={!!state.claimData.details.size}
                // sizeRequiredLanguages.includes(user.location.countryCode)
                id={'search_new_claim_size'}
            />
            {sizeRequiredLanguages.includes(user.location.countryCode.toLowerCase()) ?
                <div className={cx(style.labelFieldAbove, { [style.error]: !state.claimData.details.size, [style.good]: !!state.claimData.details.size })}>{t('common.requiredField')}</div>:
                <div className={style.labelFieldAbove}>{t('common.fieldOptional')}</div>
            }

            <div className={style.calendarLayer} id="calendarLayer" onClick={!showDatePicker ? () => setShowDatePicker(old => !old) : undefined}>
                {showDatePicker &&
                    <div className={style.layerClickCalendar}>
                        <Calendar
                            value={state.claimData.details.date}
                            onChange={onChangeCalendar}
                            locale={language || 'en'}
                            maxDate={new Date()}
                            view={'year'}
                            onClickMonth={onChangeCalendar}
                            minDate={getDaysAgo(365 * 30)}
                        />
                    </div>
                }

                <Input
                    label={t('createClaim.detDate')}
                    value={state.claimData.details.date ? formatDateMonth(state.claimData.details.date) : ''}
                    disabled
                    id={'input_new_claim_date'}
                />
                <CalendarIcon className={style.calendar} />
                <div className={cx(style.labelFieldAbove, style.labelFieldAboveDate, { [style.error]: !state.claimData.details.date, [style.good]: state.claimData.details.date })}>{t('common.requiredField')}</div>
            </div>
            {/* <div className={style.labelFieldAbove}>{t('common.fieldOptional')}</div> */}

            <div className={style.costLayer}>
                <div className={style.costKnow}>
                    <div className={style.costTitle}>
                        {state.claimData.details.costKnow ? t('createClaim.specifyValueKnow') : t('createClaim.specifyValueDontKnow')}
                    </div>
                    <Switcher
                        name='sww'
                        value={state.claimData.details.costKnow}
                        onChange={setSwitchCost}
                    />
                </div>
                {state.claimData.details.costKnow &&
                    <div className={style.costInput}>
                        <Input
                            label={t('createClaim.detCost')}
                            value={state.claimData.details.cost > 0 ? String(state.claimData.details.cost) : ''}
                            onChange={actionChangeCost}
                            className={style.inputLayer}
                            inputMode={'numeric'}
                            inputModeNoNumber
                            alwaysSmallLabel={!!isMobile}
                            rightModeData={state.branchCurrenciesByLocation.map((c: any) => {
                                return { value: c, label: c };
                            })}
                            rightModeAction={actionChangeCurrency}
                            rightModeValue={state.claimData.details.currency}
                            maxLength={10}
                            id={'input_new_claim_cost'}
                        />
                    </div>
                }
            </div>
            <div className={cx(style.labelFieldAbove, { [style.error]: state.claimData.details.costKnow && state.claimData.details.cost <=0 })}>{t('createClaim.specifyValue')}</div>
        </div>
    );
}
export default Details;
