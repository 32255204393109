import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from "rsuite";

import { ReactComponent as ChevronIcon } from 'Assets/right-arrow.svg';
import useLocationSearch from 'Helpers/useLocationSearch';
import { SearchInput, Loader, Button } from 'Components';
import styles from './LocationSearch.module.scss';
import { AddressT } from 'Helpers/googleApi';

interface ILocationSearch {
	label: string;
	value: string;
	onChange: (newLocation: string, location: ICoords, address: AddressT | null) => void;
	children: (values: React.ReactNode | string, onClick: () => void) => React.ReactNode;
	valueAsText?: boolean;
	valueOnly?: boolean;
	geocodeLocation?: boolean;
	className?: string;
	onOpenChange?: (open: boolean) => void
}

export default function LocationSearch(props: ILocationSearch) {
	const { t } = useTranslation();
	const { label, value, onChange, geocodeLocation, onOpenChange = ()=> {} } = props;
	const [searchValue, setSearchValue] = useState(value);
	const [open, setIsOpen] = useState<boolean>(false);
	const {
		list,
		loading,
		guessLocation,
		resetList,
		geocodeUserLocation,
		getPlaceCoordinatesById,
	} = useLocationSearch();
	const reverseGeocodeResultRef = useRef('');

	useEffect(() => {
		onOpenChange(open);
	}, [open, onOpenChange]);

	const toggleModal = useCallback(() => {
		setIsOpen((isOpen) => !isOpen);
		if (!searchValue) {
			setSearchValue(value);
		}
	}, [setIsOpen, searchValue, value]);

	const displayText = useMemo(() => {
		const textValue = value ? value : label;

		if (props.valueAsText) {
			if (props.valueOnly) {
				return value ? textValue : undefined;
			}

			return textValue;
		}

		return value ? (
			<span className={styles.value}>{value}</span>
		) : (
			<span className={styles.label}>{label}</span>
		);
	}, [label, value, props.valueAsText, props.valueOnly]);

	const searchInputOnChange = useCallback((evt) => {
		setSearchValue(evt.target.value);
	}, []);

	const clearInput = useCallback(() => {
		setSearchValue('');
		resetList();
	}, [resetList]);

	useEffect(() => {
		// Check that new search value was provided from user input
		if (searchValue && searchValue !== reverseGeocodeResultRef.current) {
			guessLocation(searchValue);
		}
	}, [searchValue, guessLocation]);

	useEffect(() => {
		if (geocodeLocation) {
			geocodeUserLocation().then((data) => {
				if (data) {
					reverseGeocodeResultRef.current = data.formatted_address;
					onChange(data.formatted_address, data.location, data.address);
					setSearchValue(data.formatted_address);
				}
			});
		}
	}, [geocodeUserLocation, onChange, geocodeLocation]);

	const renderHeader = () => {
		return (
			<SearchInput
				placeholder={t('common.search')}
				value={searchValue}
				onChange={searchInputOnChange}
				clear={clearInput}
			/>
		);
	};

	function renderList() {
		if (loading) {
			return <Loader />;
		}

		if (list.length === 0) {
			return null;
		}

		return list.map(({ name, ...rest }, idx) => {
			async function onElementClick() {
				toggleModal();
				try {
					const { coords, address } = await getPlaceCoordinatesById((rest as any).placeId);
					onChange(name, coords, address);
				} catch (err) {
					console.error(err)
				}
			}

			return (
				<li key={idx} onClick={onElementClick}>
					<span>{name}</span>
					<ChevronIcon className={styles.chevronIcon} />
				</li>
			);
		});
	}

	return (
		<>
			{props.children(displayText, toggleModal)}

			<Modal
				onClose={toggleModal}
				open={open}
				className={styles.modalContent}
			>
				<Modal.Header>
					{renderHeader()}
				</Modal.Header>
				<Modal.Body>
					<ul className={styles.locationList}>{renderList()}</ul>
				</Modal.Body>
				<Modal.Footer className={styles.closeLayer}>
					<Button onClick={toggleModal}>Zamknij</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}