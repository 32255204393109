import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import cx from 'classnames';

import { StateT as UserStateT } from '../../Store/modules/user';
import { useDeviceType } from 'Helpers/responsiveContainers';
import DrawerMenu from "Components/DrawerMenu/DrawerMenu";
import { helpCenterAvailableLanguages } from 'Helpers/i18';
import { ReactComponent as Logo } from 'Assets/logo.svg';
import { StateT as I18StateT } from "Store/modules/i18n";
import Footer from 'Components/Footer/Footer';
import styles from './Header.module.scss';
import { AppStateT } from "Store/modules";

interface IProps {
	back?: boolean | string;
	footer?: boolean;
}
export type UrlActionTypeT = 'login'|'about'|'terms'|'privacy'|'language'|'help'|'privacyPolicy' |'personalInfo' | 'logOut';

const FOOTER_LINKS_FIRST_ROW = [
    { name: 'footer.signIn', path: '/login' },
    { name: 'footer.aboutUs', path: '/about' },
    { name: 'footer.help', path: '/help' },
    { name: 'footer.language', path: '/lang' },
    { name: 'footer.privacyPolicy', path: '/privacyPolicy' },
];

const FOOTER_LINKS_SECOND_ROW = [
    { name: 'footer.privacySettings', path: '/privacy' },
    { name: 'footer.termsAndConditions', path: '/terms' },
];

export default function Header({ back, footer }: IProps) {
	const actualLanguage = useSelector<AppStateT, string>(({ i18n }) => i18n.language);
	const { loggedIn } = useSelector<AppStateT, UserStateT>(({ user }) => user);
	const { source } = useSelector<AppStateT, I18StateT>((data) => data.i18n);
	const toggleDrawer = (newOpen: boolean) => setOpen(newOpen);
	const [open, setOpen] = useState(false);
	const { isMobile } = useDeviceType();
	const navigate = useNavigate();

	const footerLinksFirstRow = useMemo(() => 
		FOOTER_LINKS_FIRST_ROW.filter(link => link.name === 'footer.help' ? helpCenterAvailableLanguages.includes(actualLanguage) : true), 
	[actualLanguage]);

	const footerLinksSecondRow = useMemo(() => 
		isMobile ? FOOTER_LINKS_SECOND_ROW : FOOTER_LINKS_SECOND_ROW.filter(link => link.name !== 'footer.privacySettings'), 
	[isMobile]);

	const goToLogin = useCallback(() => {
		if (source && !loggedIn){
			return window.location.href = source;
		}
		navigate('/login');
	}, [navigate, source, loggedIn]);

	return (
		<header className={styles.root}>
			<div className={!isMobile ? styles.logoDesktop : styles.logo} onClick={goToLogin}><Logo /></div>
			{isMobile &&
				<div className={cx(styles.icon)}>
					<DrawerMenu
						open={open}
						toggleDrawer={toggleDrawer}
						footerLinksFirstRow={footerLinksFirstRow}
						footerLinksSecondRow={footerLinksSecondRow}
						bigMenuButton
					/>
				</div>                               
			}
			{footer && <Footer />}
		</header>
	);
}
